.customToggleBtnGroup {
  border-radius: 30px;
  overflow: hidden; // This ensures that the children do not overflow the rounded corners
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 30px !important;

  .btn {
    border: none;
    border-radius: 30px !important;
    background: transparent !important;

    &.selected {
      background-color: var(--primary) !important;
      color: white !important;
      box-shadow: 0px 0px 5px grey;
    }

    // Remove the default browser appearance of the radio button
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
    }
  }
}

.greyBg {
  background-color: var(--secondary);
}