@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

@function rem-calc($size) {
  $remSize: calc($size / 16);
  @return #{$remSize}rem;
}

html,
body {
  height: 100%;
  font-family: "Roboto", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--white);
}

/* 🚨🚨🚨 BOOTSTRAP EXTENTIONS 🚨🚨🚨 */
/* These extension are not in the bootstrap docs, but are logical extensions necessary to create some of the UI */

/* LAYOUT */

// .row > * + * {
//   margin-left: 10px;
// }

/* CONTENT */

/* colors */

.text-body {
  color: #666666 !important;
}
.bg-dark {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
}

p {
  color: #333333;
}

h1 {
  font-size: 48px;
  line-height: 58px;
}

h2 {
  font-size: 36px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.d-sm-flex .h5 {
  font-size: 14px;
}
.h6 {
  font-weight: normal;
  font-size: rem-calc(12);
}
.d-sm-flex .h6 {
  font-size: 12px;
}

.d-lg-block p:not(.lead) {
  font-size: 14px;
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: #666 !important;
}
.text-warning {
  color: var(--warning) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-success {
  color: var(--success) !important;
}
.text-underline-success {
  text-decoration: underline var(--success);
}

/* navbar */

.navbar {
  min-height: 75px;
}

/* breadcrumb */
.breadcrumb {
  border-radius: 0;
}
.breadcrumb-item a {
  font-size: 10px;
  color: var(--gray);
}
.breadcrumb-item.active a {
  color: var(--primary);
  font-weight: 600;
}

nav.d-lg-block .breadcrumb {
  height: 60px;
}
nav.d-lg-block .breadcrumb-item a {
  font-size: 14px;
}

/* button */
.btn {
  font-weight: bold;
  min-width: 100px;
}

.btn-light {
  font-weight: normal;
  border-radius: 0;
  background-color: white;
  border: 0;
}
.btn-light:active {
  color: var(--dark);
  color: red;
  opacity: 0.5;
}
.btn-light:hover {
  font-weight: normal !important;
  border-radius: 0 !important;
  background-color: white !important;
  border: 0 !important;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: white;
  border-color: var(--primary);
}

#btn-add-payment {
  --button-width: none;
}

.btn-outline-primary:hover {
  border-color: var(--primary);
  background-color: white;
  color: var(--primary);
  opacity: 0.5;
}
.btn-outline-primary:active {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  color: white !important;
  opacity: 0.2 !important;
}
.btn-outline-secondary {
  color: var(--primary);
  background-color: white;
  border-color: var(--primary);
  border-radius: 30px;
  font-weight: bold;
  padding: 5px 20px;
}
.btn-outline-secondary:focus {
  outline: none;
}
.btn-outline-secondary:hover {
  border-color: var(--primary);
  background-color: white;
  color: var(--primary);
  opacity: 0.5;
}
.btn-outline-secondary:active {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  color: white !important;
  opacity: 0.2 !important;
}

.btn-light:hover {
  color: #fff;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.btn-link {
  color: var(--primary);
  font-weight: bold;
  text-decoration: none;
}

#file-download {
  margin-right: 0.4rem;
  margin-bottom: 0.3rem;
}

.btn-link:hover {
  text-decoration: underline;
}

/* button group */
.btn-group .active {
  background-color: var(--primary) !important;
}
.btn-group-toggle label {
  border-radius: 100px;
  border: 0;
}
.btn-group-toggle .btn input:checked ~ p {
  font-weight: bold;
}
.btn-group-toggle p {
  font-weight: normal;
}
.btn-group-toggle .btn {
  min-width: 0;
  border: solid white 1px !important;
  background-color: #ddd;
  color: #aaa;
}

/* dropdowns */

.dropdown .btn-primary {
  border-radius: 15px;
  color: #666666 !important;
  font-weight: bold;
  background-color: white !important;
}
.dropdown-menu {
  position: absolute;
  top: 45px;
  width: 300px;
  border-color: #ddd;
}
.dropdown-menu::before {
  content: " ";
  border: solid 1px #ddd;
  height: 15px;
  width: 15px;
  position: absolute;
  transform: rotate(45deg);
  background-color: white;
  top: -8px;
  left: 50px;
  border-right-color: white;
  border-bottom-color: white;
}

.dropdown-item:hover {
  cursor: pointer;
}
.dropdown-item.active {
  background-color: white;
  color: var(--primary);
  font-weight: bold;
}
.dropdown-item.active::after {
  content: "\f14a";
  font-family: "Font Awesome 5 Free";
  position: relative;
  padding-left: 10px;
}

.dropdown-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.btn-primary.dropdown-toggle.active {
  color: #fff !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-primary:not(:disabled):not(.disabled):active.dropdown-toggle::after,
.active.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ESlice%3C%2Ftitle%3E%3Cpath%20d%3D%22M1.41%202L6%206.58%2010.59%202%2012%203.41l-6%206-6-6z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  top: 10px;
  right: 20px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ESlice%3C%2Ftitle%3E%3Cpath%20d%3D%22M1.41%202L6%206.58%2010.59%202%2012%203.41l-6%206-6-6z%22%20fill%3D%22%23006DC6%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  margin-left: 6px;
  vertical-align: middle;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

/* forms */

.form-group__search {
  position: relative;
}

.form-group__search:before {
  content: "";
  height: 18px;
  width: 18px;
  top: 10px;
  left: 10px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ESlice%3C%2Ftitle%3E%3Cpath%20d%3D%22M12.5%2011h-.79l-.28-.27C12.41%209.59%2013%208.11%2013%206.5%2013%202.91%2010.09%200%206.5%200S0%202.91%200%206.5%202.91%2013%206.5%2013c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L17.49%2016l-4.99-5zm-6%200C4.01%2011%202%208.99%202%206.5S4.01%202%206.5%202%2011%204.01%2011%206.5%208.99%2011%206.5%2011z%22%20fill%3D%22%23006DC6%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  position: absolute;
}

.form-group__search .form-control {
  padding-left: 2.25rem;
}

.form-control {
  border-radius: 5px;
  border-color: #dddddd;
}

.form-control::placeholder {
  color: #aaaaaa;
}

.address-form-checkbox {
  font-weight: bold;

  .form-check {
    padding: 0;
    display: flex;
    align-items: center;

    .form-check-input {
      position: relative;
      margin: 0px 20px 0px 0px;
    }
  }
}

label {
  margin-bottom: 0;
}
label p.active {
  font-weight: bold;
  color: var(--primary);
}
div.active::after {
  font-weight: 600;
  content: "\f14a";
  font-family: "Font Awesome 5 Free";
  color: var(--primary);
}
/* card */

.location-list-scroll .card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) !important;
}

.card-hovered {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) !important;
}

.card-title {
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.card--shadow {
  box-shadow: 0 2px 4px rgba(221, 221, 221, 0.7);
}

.badge-primary {
  background-color: var(--primary);
}
.badge-secondary {
  border: solid 1px var(--primary);
  background-color: white;
  color: var(--primary);
}

.card-footer {
  // Will fix the Card Box Spacing
  --button-width: none;
}

/* List */
li {
  list-style: none;
}

/* Modal */
/* .modal-dialog{
} */
.modal-xl {
  max-width: none;
}
.modal-header,
.modal-footer {
  border: none;
}

/* UTILITIES */

.font-xsmall {
  font-size: 12px;
  line-height: 14px;
}

.font-small {
  font-size: 14px;
  line-height: 16px;
}

.font-regular {
  font-size: 16px;
  line-height: 19px;
}

small {
  font-size: 14px;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  // z-index: 9;
}

.border {
  border-color: #ddd !important;
}
.border-primary {
  border-color: var(--primary) !important;
}
.border-danger {
  border-color: var(--danger) !important;
}
.border-warning {
  border-color: var(--warning) !important;
}
// .border.border-secondary {
//   border-color: $secondary !important;
// }
.fixed-bottom {
  height: 80px;
}
/* end of synapse's code */

.mapContainer {
  position: "absolute";
  width: "100%";
  height: "100%";
}
.search-icon {
  color: var(--primary);
}

button .arrow-icon {
  color: var(--primary);
  margin-left: 8px;
}

.slider-distance {
  background: #fff;
  border: 1px solid #ddd;
  padding: 25px 30px;
  box-shadow: 0 6px 6px -6px #222;
  border-radius: 0.25rem !important;
  max-width: 300px;
  padding-right: 35px !important;
  position: absolute;
  z-index: 3;
  left: 0px;
  width: 50%;
  top: 40px;
}
.rc-slider-dot {
  height: 5px;
  bottom: -1px;
  border: none;
  margin: 0;
  width: 2px;
  border-radius: 0;
}
.rc-slider-rail {
  height: 5px;
  border-radius: 10px;
}
.rc-slider-track {
  height: 5px;
  border-radius: 10px;
}
span.rc-slider-mark-text.rc-slider-mark-text-active {
  color: var(--primary);
}
.slide-marks .rc-slider-dot {
  /* width: 4px !important; */
  background: #fff;
  /* border: 2px solidvar(--primary); */
}
.slide-marks .rc-slider-mark {
  top: -17px !important;
}
.slide-marks .rc-slider-track {
  background: var(--primary) !important;
}
.slide-marks .rc-slider-dot-active {
  border: 1px solid #fff !important;
}
.slide-marks .range-marks {
  color: #fff;
}
.range-marks .rc-slider-dot {
  width: 4px !important;
  background: #fff;
  border: 2px solidvar(--primary);
}
.range-marks .rc-slider-mark {
  top: 2px !important;
  left: 40px !important;
  color: #fff !important;
}
.range-marks .rc-slider-track {
  background: var(--primary) !important;
  height: 35px !important;
  top: -8px !important;
  border-radius: 30px !important;
}
.range-marks .rc-slider-dot-active {
  border: 1px solid #fff !important;
}
.range-marks .range-marks {
  color: #fff;
}
.range-marks .rc-slider-mark-text-active {
  color: #fff;
}
.range-marks .rc-slider-mark-text {
  color: #fff;
}

p.go-back {
  color: var(--primary);
}

p.go-back:hover {
  cursor: pointer;
}

a.go-back {
  color: var(--primary);

  &:hover {
    cursor: pointer;
  }
}

.location-marker-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -45px;
  left: -45px;
  height: 90px;
  width: 90px;
  transition: all 0.2s ease-in-out;
}

.location-marker-container svg.location-marker-pin {
  width: 16px;
  height: 24px;
}

.location-marker-container.location-marker-hovered {
  z-index: 2;
  transform: scale(1.2);
  left: calc(-50% - 9px);
  top: calc(-100% - 9px);
}

.location-marker-name {
  color: #4a4a4a;
  max-width: 74px;
  height: 24px;
  margin-bottom: 5px;
  padding: 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 1px 4px #999;
  background: #fff;
  border-radius: 50px;
  line-height: 24px;
  transition: 0.2s ease-in-out max-width;
}

.location-marker-name.location-marker-name-hovered {
  max-width: 300px;
  color: var(--primary);
  font-weight: bold;
  border: 1px solidvar(--primary);
}

.cluster-marker {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: -10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  position: relative;
  font-variant: proportional-nums;
}

.cluster-marker-hovered {
  transform: scale(1.2);
  z-index: 2;
}

/* =============== Landing Page Location Section ================ */

/* Landing Page Location Section */

.landing-page-location-list {
  padding: 30px;
}

.landing-page-location-list-column {
  display: block;
  padding: 0 40px;
  font-size: 18px;
}

.landing-page-location-list-column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.landing-page-location-button {
  float: right;
  background-color: var(--primary);
  border: none;
  color: white;
  width: 50%;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

.landing-page-location-button:hover {
  text-decoration: none;
  color: white;
}

.list-check {
  color: #eb7935;
}

/* Landing Page Location Tier Tabs */
/**
Section 2 was only included to show how the Package card would look like.
**/

.section-2 {
  height: 700px;
  z-index: -2;
}

.package-card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  /* width: 145px; */
  margin-top: 50px;
  background: #fff;
}

.package-card:before {
  content: "";
  position: absolute;
  background: #1e6f9f;
  background: var(--primary-color);
  top: -3%;
  left: -3%;
  width: 106%;
  height: 114%;
  border-radius: 5px;
  z-index: -5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

/* .package-name
{
  content: '';
  position: absolute;
  top: -17px;
  left: 16%;
  width: 70%;
  height: 30px;
  background: #1E6F9F;
  background:  var(--primary-color);
  transform: skew(-28deg, 0deg);
  z-index: -1;
}

.package-name {
  color: #fff;
  margin-top: -16px;
  margin-bottom: -15px;
  font-size: 19px;
  font-style: italic;
  font-weight: 900;
  z-index: 2;

} */

.package-locations {
  font-size: 20px;
  font-weight: 900;
}

.package-tag-locations {
  font-size: 16px;
  margin-top: -10px;
}

/* .package-price-amount {
  font-weight: 900;
  font-size: 51px;
  font-style: italic;
} */

.package-tag-permonth {
  margin-top: -20px;
  font-style: italic;
}

.card-pricing.popular {
  z-index: 1;
  border: 3px solid #1e6f9f;
}

.card-pricing .bg-header {
  background-color: #1e6f9f;
}

.card-pricing .card-footer {
  background-color: #1e6f9f;
}

.tier-tabs {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

.tier-tab {
  padding: 20px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-bottom-width: 0;
}

.tier-tab:first-child {
  border-radius: 5px 0 0 0;
}

.tier-tab:last-child {
  border-radius: 0 5px 0 0;
}

.tier-tab.current-selected-tab {
  background: white;
  border: 2px solidvar(--primary);
  border-bottom-width: 0;
}

.tier-tab-name {
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.tier-tab-price {
  font-weight: bold;
  font-size: 25px;
}

.tier-tab-price span {
  font-weight: normal;
  color: #7d7d7d;
  font-size: 10px;
}

/* Location Search Tier List */

.selected-tier-price {
  text-decoration: line-through;
}

.current-selected-tier-price {
  text-decoration: none;
  font-weight: bold;
}

/* ================= Map Section ================== */

.map-section {
  width: 66.6%;
  border-left: 1px solidvar(--primary);
  margin-bottom: 20px;
}

.map-filters {
  margin: 0 10px 10px;
}

.map-fields__top {
  top: 10px;
  z-index: 2;
  left: 0;
  right: 0;
}

/* ================ Location Section =============== */

/* Location List */

.location-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 55vh;

  @media only screen and (max-width: 600px) {
    height: auto;
  }
}

.location-list-scroll::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

/* Location List Item */

.location-list-item-elite {
  font-size: 12px;
  font-weight: bold;
  display: inline;
  color: var(--primary);
  text-transform: uppercase;
  border: 1px solidvar(--primary);
  padding: 3px 10px;
}

.location-list-item-category {
  font-size: 0.8em;
}

/* GeoSuggest Input */

/* .input-group-prepend {
   width: 100%;
} */

.search-icon.input-group-text {
  border-right: 0;
  background-color: white;
  border-radius: 5px 0 0 5px;
}

.location-search-input.form-control {
  padding: 0.3rem;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  width: 79%;
  left: 65px;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border-top: none;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: max-height 0.2s, border 0.2s;
}

.suggestion-item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
  border-top: 1px solid #ced4da;
}

.suggestion-item--active {
  background: #267dc0;
  color: #fff;
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
  border-top: 1px solid #ced4da;
}

.horizontal-location-list {
  border: 1px solid white;
  border-radius: 5px;
}

/* .main-content{
  height:calc(100vh - 215px);
  overflow:hidden;
} */
.left-content {
  /* overflow-y:scroll!important; */
  overflow-y: visible;
  padding-top: 9px;
}

.fa-check-circle {
  color: var(--success);
}
.fa-trash {
  color: var(--primary);
  min-width: 0px !important;
  background-color: none;
  border: 0;
}
.rc-slider-mark-text {
  padding-right: 10px;
}

/* ACCOUNT OVERVIEW */
.content-height {
  height: calc(100vh - 75px) !important;
}

.side-nav {
  width: 250px;
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.mini-avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.gradient {
  background: rgb(153, 254, 255);
  background: linear-gradient(
    135deg,
    rgba(153, 254, 255, 1) 0%,
    rgba(0, 109, 198, 1) 100%
  );
}

.offset-alignment {
  position: relative;
  bottom: 35px;
}

.offset-alignment-spacer {
  position: relative;
  bottom: 20px;
}

.custom-table {
  border-radius: 20px;
}

.disclaimer-height {
  height: 150px;
  overflow: scroll;
}

.spinner {
  height: 50px;
  width: 50px;
}

.select-fam-mem {
  /* border: 1px solid #f2f2f2; */
  border-radius: 0.25rem;
  display: block;
  position: relative;
  cursor: pointer;
}

.select-fam-mem.active {
  color: var(--primary);
  border: 1px solidvar(--primary);
  font-weight: bold;
}

.select-fam-mem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.select-fam-mem .custom-checkbox {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solidvar(--primary);
}

.select-fam-mem .custom-checkbox::after {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  height: 0px;
  width: 0px;
  border-radius: 3px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
}

.select-fam-mem input:checked ~ .custom-checkbox {
  background: var(--primary);
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
}

.select-fam-mem input:checked ~ .custom-checkbox::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.package-selection-location-marker-info {
  position: absolute;
  top: -50px;
  white-space: nowrap;
  max-width: 400px;
  padding: 5px;
  box-shadow: 0 1px 5px #999;
  background: #fff;
  border-radius: 5px;
  z-index: 4;
}

.package-selection-location-marker-arrow:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  top: 12px;
  z-index: 5;
  border: 7.45px solid;
  border-color: #fff transparent transparent transparent;
}

a:active {
  text-decoration: none;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.loading-animation {
  background: linear-gradient(238deg, var(--gray), var(--gray-dark));
  background-size: 400% 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  -o-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 81% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 81% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 81% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 81% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 81% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 81% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 81% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 81% 0%;
  }
}

.react-date-picker__calendar {
  width: 350px !important;
  max-width: 100vw !important;
  position: absolute !important;
  top: 100% !important;
  bottom: unset !important;
  left: 0px !important;
  right: unset !important;
}

.locations-sticky {
  &__search {
    @media only screen and (max-width: 600px) {
      position: sticky;
      position: -webkit-sticky;
      top: 81px;
      z-index: 1001;
    }
  }
}

.react-dropdown-select-item {
  border: none;
  color: #333 !important;
}

.react-dropdown-select-item:hover {
  background: var(--primary) !important;
  color: white !important;
  border-radius: 5px !important;
}

.react-dropdown-select-item-selected {
  background: none !important;
  color: var(--primary) !important;
  border: 1px solidvar(--primary) !important;
  border-radius: 5px !important;
}

.suspended-warning {
  color: #000 !important;
  border-left: 13px solid #ba8319 !important;
  font-size: 14px;
  font-style: normal !important;

  &__title {
    font-size: 18px;
    font-weight: bold;
    color: #ba8319;
  }

  &__icon {
    position: absolute;
    top: 0px;
  }

  span {
    position: relative;
    padding: 0 25px 0 0;
  }
}
.danger-alert {
  color: #000 !important;
  border-left: 13px #a02020 solid !important;
}

.wrapit {
  // display: inline;
  // @media only screen and (max-width: 992px) {
  //   display: flex;
  flex-wrap: wrap;
  //}
}

.flex-card {
  width: 240px !important;
  min-height: 200px !important;
}

/** Classes for the displayed toast **/
// .Toastify__toast {
//   // background: #fff !important;
//   // min-width: 300px;
// }
// .Toastify__toast--rtl {
// }
// .Toastify__toast--dark {
// }
// .Toastify__toast--default {
// }
// .Toastify__toast--info {
// }
// .Toastify__toast--success {
//   color: #000 !important;
//   border-left: 13px solid #82b265 !important;
//   font-size: 14px;
//   font-style: normal !important;

//   &__title {
//     font-size: 16px;
//     font-weight: bold;
//     color: #82b265;
//   }

//   &__icon {
//     position: absolute;
//     top: 0px;
//   }
// }
// // .Toastify__toast--warning {
// // }
// .Toastify__toast--error {
//   color: #000 !important;
//   border-left: 13px solid #a02020 !important;
//   font-size: 14px;
//   font-style: normal !important;

//   &__title {
//     font-size: 16px;
//     font-weight: bold;
//     color: #a02020;
//   }

//   &__icon {
//     position: absolute;
//     top: 0px;
//   }
// }
// .Toastify__toast-body {
// }

.select-fp {
  @media only screen and (min-width: 768px) {
    width: 134%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.parallelograms {
  font-size: 44pt;
  color: var(--white);
  font-weight: 700;
  z-index: 2;
  text-align: center;
  position: relative;
}

.parallelograms:before {
  content: "";
  width: 70%;
  height: 90%;
  position: absolute;
  right: 15%;
  top: 0;
  background: #106bb5 !important;
  transform: skew(-28deg, 0deg);
  z-index: -1;
}

.parallelograms:after {
  content: "";
  width: 70%;
  height: 90%;
  position: absolute;
  right: 10%;
  top: 12px;
  background: var(--hcscGreen);
  transform: skew(-28deg, 0deg);
  z-index: -2;
}

.icon-alignment {
  margin-top: -5px;
}

.hqa {
  h5 {
    color: #004085 !important;
  }
  a {
    font-weight: bold;
    text-decoration: underline;
    color: #004085;
  }
}

.primeOnDemand-lp {
  //height: 400px;
  position: relative;
  //z-index: -1;
  //background-image: url('https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2021/07/Les-Mills-Hero_Gradient-min.png');
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: right;

  h1 {
    @media only screen and (min-width: 1200px) {
      font-size: 60px !important;
    }
    @media only screen and (min-width: 992px) {
      font-size: 50px !important;
    }

    @media only screen and (max-width: 768px) {
      font-size: 35px !important;
    }
    // @media only screen and (max-width: 768px) {
    //   font-size: 35px !important;
    // }
    // @media only screen and (min-width: 600px) {
    //   font-size: 30px !important;
    // }
    @media only screen and (max-width: 600px) {
      font-size: 22px !important;
      margin-top: -35px;
    }
  }
  .lm-logo {
    max-width: 163px;
    @media only screen and (min-width: 1200px) {
      max-width: 200px;
    }
    @media only screen and (max-width: 600px) {
      width: 35%;
      margin-top: -55px;
    }
  }
  .lm-back {
    position: relative;
  }
}

.primeOnDemand-lp-bg {
  background-color: #f2f2f2;

  button[type="button"]:focus-visible {
    outline: none !important;
  }

  button[type="button"]:focus {
    outline: none;
  }
}

button[type="button"]:focus-visible {
  outline: none !important;
}

.duration-border {
  border-right: 0.7px solid #bfbfbf;
}

.video-category {
  position: relative;
}
.video-category-section {
  padding: 5px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 90%;
  color: white;
}

.video-category-section2 {
  padding: 20px 5px;
}

.video-categories-tabs {
  background: #cbcbcb 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  color: #414141;
  display: inline-flex;
  padding: 5px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  white-space: normal;

  @media only screen and (max-width: 770px) {
    display: inline-block !important;
    border-radius: 2vw !important;
    margin-bottom: 5px !important;
    font-size: 2.8vw !important;
    text-align: center !important;
  }

  @media only screen and (max-width: 410px) {
    font-size: 3.5vw !important;
    border-radius: 4vw;
  }
}
.video-card {
  background: #ffffff;
  box-shadow: 1px 1px 10px #00000029;
  border-radius: 10px;
  margin: 10px;
}

.video-title {
  font-size: 24px !important;
  color: var(--dark) !important;
}

.slider-list {
  min-height: 470px !important;
}

.video-player {
  display: block;
  margin: auto;
}

.video-container {
  background: #707070;
}

.video-details-container {
  background-color: #f2f2f2;
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
.video-details-list {
  background-color: #f2f2f2;
}

.video-size {
  width: 720px;
  height: 420px;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-top: -0.81666em;
  margin-left: -1.5em;
  content: "\f101" !important;
}

.lm-vdlink {
  color: var(--primary);

  @media only screen and (max-width: 735px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 680px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 635px) {
    font-size: 12px;
  }
}

.sv-border {
  color: #000;
  box-shadow: 1px 1px 10px var(--primary);
}

.video-card-img {
  //width: 395px;
  //height: 130px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.slides:focus-visible {
  outline: none;
}

.lm-text {
  position: absolute;
  top: 0;
}

.wave-svg {
  height: 100%;
  width: 100%;
}

.video-js .vjs-big-play-button {
  width: 50px !important;
  border: 3px solid #fff !important;
  background-color: none !important;
  border-radius: 50% !important;
}
.slider {
  @media only screen and (max-width: 600px) {
    width: 137% !important;
  }
}
.slider-control-centerleft {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.slider-control-centerright {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.additionalText p {
  font-size: 0.8rem;
  line-height: 0.2rem;
}

.react-multiple-carousel__arrow {
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;
  line-height: normal;
  position: absolute;
  top: 0;
  background: #f2f2f2 !important;
  -webkit-box-shadow: 1px 1px 10px #00000074;
  box-shadow: 1px 1px 10px #00000074;
  height: 100%;
  padding: 0 1.25rem;
  font-size: 2.625rem;
  border-radius: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
}

.react-multiple-carousel__arrow--left {
  left: 0px !important;
}

.react-multiple-carousel__arrow--right:focus-visible {
  outline: none !important;
}

.react-multiple-carousel__arrow--left:focus-visible {
  outline: none !important;
}
.react-multiple-carousel__arrow::before {
  color: var(--primary) !important;
  font-weight: bolder;
}

.its-a-link {
  cursor: pointer;
}

.its-a-link:hover {
  text-decoration: none !important;
}

.title-resp {
  font-size: 20px;

  @media only screen and (max-width: 735px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 680px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 635px) {
    font-size: 12px;
  }
}

.download-icon {
  margin-top: -8px;
  margin-right: 5px;
}

.package-tile {
  margin-right: 10px;
}
