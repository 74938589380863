.checkout {
  &__zuoraText {
    color: #737373;
    font-size: 12px;
    margin-left: 8px;
  }
  &__zuoraButton {
    font-size: 12px;
    background: none;
    border: none;
    color: #737373;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }
}
