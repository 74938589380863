.title {
  margin-top: 50px;
  text-align: center;
  color: #000000;
}

.subTitle {
  width: 100%;
  max-width: 704px;
  text-align: center;
  margin: 0px auto 40px;
  color: #333333;
  font-family: "Roboto";
  font-weight: Regular;
  font-size: 20px;
}

.eligibleButton {
  margin: 30px 0 40px;
  text-align: center;
}
