:root {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    font-family: "Roboto", sans-serif;
    color: var(--text);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--white);
  }

  .platform-colors {
    &__bg {
      background-color: var--(platform-gray);
    }
  }

  .content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  main {
    flex-grow: 1;
  }

  header,
  main,
  footer {
    flex-shrink: 0;
  }

  h1 {
    @media only screen and (max-width: 576px) {
      font-size: rem-calc(32);
    }
  }

  h2 {
    @media only screen and (min-width: 576px) {
      // font-size: 1.8rem;
    }
  }

  .h2 {
    font-size: 2vw;
  }

  // ul{
  // 	li{
  // 		font-weight: 400;
  // 		font-size: 20px;
  // 		font-family: 'Roboto';
  // 		color: var(--text);
  // 	}
  // }

  a:hover {
    color: var(--white);
    text-decoration: underline;
  }

  .btn-primary {
    // background-color: var(--primary);
    background-color: var(--clientPrimary);
    border-color: var(--clientPrimary);
    color: var(--white);
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
  }
  .landing-page {
    background-color: var(--landingPageButton);
    border-color: var(--landingPageButton);
    color: var(--white);
  }

  p.go-back {
    color: var(--primary);
  }

  .extra-margin-top {
    margin-top: 55px;
  }
  s .extra-margin-bottom {
    margin-bottom: 55px;
  }

  .extra-padding-bottom {
    padding-bottom: 55px;
  }

  .overlap-img {
    margin-top: -30px;
  }

  .white {
    color: var(--white) !important;
  }

  .blue {
    color: var(--primary) !important;
  }

  .primary {
    color: var(--primary) !important;
  }

  .text-dark-gray {
    color: var(--dark-gray) !important;
  }

  // border danger doesnt turn the background into red so im adding a border danger manually

  .border-danger {
    border: 1px solid #dc3435 !important;
  }

  .blue-btn-primary {
    color: var(--white);
    background-color: var(--primary);
    padding: 15px 60px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-size: rem-calc(17);
    margin-top: 20px;

    &__locations {
      padding: 8px 25px;
      color: var(--white);
      background-color: var(--primary);
      border: none;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      font-size: rem-calc(17);
      margin-top: 20px;
    }
  }

  .blue-btn {
    //@include button-bg(var(--primary));
    background-color: var(--primary);
    color: var(--white);
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-size: 17px;
    &__rounded {
      color: var(--white);
      background-color: var(--primary);
      padding: 10px 50px;
      border: none;
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      // @media screen and (max-width: 991px) {
      //   padding: 15px 20px !important;
      // }
      // @media screen and (max-width: 768px) {
      //   padding: 10px !important;
      // }
      // @media screen and (max-width: 576px) {
      //   padding: 0.25rem 3rem !important;
      // }
    }
    &__rounded-home {
      color: var(--white);
      background-color: var(--primary);
      padding: 10px 50px;
      border: none;
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      @media screen and (max-width: 991px) {
        padding: 15px 20px !important;
      }
      @media screen and (max-width: 768px) {
        padding: 10px !important;
      }
      @media screen and (max-width: 576px) {
        padding: 0.25rem 3rem !important;
      }
    }
  }
  .blue-btn-small {
    color: var(--white);
    background-color: var(--primary);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  }

  .white-btn {
    color: var(--primary);
    background-color: var(--white);
    padding: 15px 50px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-size: rem-calc(17);
    margin-top: 20px;

    .fa-chevron-right {
      margin-left: 7px;
    }
  }

  .banner {
    width: 100%;
    padding: 20px;
    background-color: var(--secondary);
    color: var(--white);
    font-size: 20px;
    z-index: 1001;
    position: relative;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .section-nav {
    background-color: var(--white);
  }

  #account-header {
    color: rgb(255, 255, 255);
  }
  .navbar-nav {
    .nav-item {
      margin: 0 rem-calc(20);
    }

    .nav-link {
      color: var(--basicNavHeader);
      white-space: nowrap;

      &.active {
        color: var(--activeNavHeader);
        font-weight: bold;
      }
    }

    .nav-padding {
      padding-top: 20px;
    }
  }

  #account-header-bold {
    color: white;
    font-weight: 600;
  }

  .nav-container {
    position: relative;

    // &::before {
    //   content: '';
    //   width: 245px;
    //   height: 100%;
    //   position: absolute;
    //   right: 40px;
    //   top: 0px;
    //   background: var(--light-gray);
    //   z-index: -1;
    //   transform: skew(-28deg, 0deg);
    // }

    // &::after {
    //   content: '';
    //   width: 100px;
    //   height: 100%;
    //   position: absolute;
    //   right: 0px;
    //   top: 0px;
    //   background: var(--light-gray);
    //   z-index: -1;
    // }
  }

  // .navbar-collapse.show{
  // 	background: var(--white);
  // 	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  //     z-index: 10;
  // }

  .apps {
    img {
      display: inline-block;
    }
  }

  .helper {
    position: relative;
    img {
      position: absolute;
      right: 105px;
    }
  }

  .aligns-right {
    float: right;
  }

  .align-left {
    float: left;
  }

  .nav-cta-enroll {
    z-index: 1;
  }

  .section-1 {
    height: 600px;
    position: relative;
    z-index: 1;
    background-color: var(--primary);
    background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/Header-Image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media only screen and (max-width: 768px) {
      background-position: right center;
    }

    @media only screen and (max-width: 576px) {
      height: 275px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 10%;
      left: 3%;
      width: 50%;
      height: 95%;
      opacity: 0.8;
      filter: alpha(opacity=80); /* For IE8 and earlier */
      background: var(--secondary);
      transform: skew(-28deg, 0deg);
      z-index: -1;

      @media only screen and (max-width: 769px) {
        top: 75%;
        left: -60%;
        width: 145%;
        height: 50%;
      }

      // @media only screen and (max-width: 768px) {
      // 	top: 78%;
      //     left: 39%;
      //     width: 40%;
      //     height: 47%;
      // }

      @media only screen and (max-width: 576px) {
        top: 480px;
        left: -61%;
        width: 145%;
        height: 45%;
      }

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    .hero-left:before {
      content: "";
      position: absolute;
      top: 50px;
      left: -70%;
      width: 123%;
      height: 150%;
      background: #106bb5 !important;
      transform: skew(-28deg, 0deg);
      z-index: -1;

      @media only screen and (max-width: 430px) {
        transform: none;
      }

      @media only screen and (max-width: 768px) {
        top: 415px;
        left: -70%;
        width: 151%;
        height: 60%;
      }

      @media only screen and (max-width: 576px) {
        top: 0;
        left: -20%;
        width: 36%;
        height: 60%;
      }

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    p {
      color: var(--white);
    }

    &__mobile {
      font-size: rem-calc(32);
      font-weight: 600;
    }

    &__mobile-sub {
      font-size: rem-calc(16);
      font-weight: 600;
    }
  }

  .hero-s1 {
    display: none;

    @media only screen and (max-width: 576px) {
      display: block;
    }
  }

  .hero-s1-button-div {
    background-color: var(--white);
    position: fixed;
    bottom: 0;
    z-index: 998;
    width: 100%;
    height: auto;
    padding: 16px 12px;
    margin: 0 auto;
    left: 0;

    @media only screen and (min-width: 577px) {
      display: none;
    }
  }

  .hero-s1-button {
    display: none;
    width: 100%;
    margin: 25px 0;

    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  .lh1 {
    position: relative;

    &__text {
      @media only screen and (max-width: 768px) {
        position: absolute;
        top: 330px;
      }
    }

    h1 {
      @media only screen and (max-width: 768px) {
        font-size: rem-calc(40);
      }
    }

    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .gyms {
    background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/gyms.png");
    background-repeat: no-repeat;
    background-position: right;
    padding: 175px 0 188px;

    @media only screen and (max-width: 768px) {
      background: none;
    }
  }

  .footer-bottom-background {
    background-color: #106bb5 !important;
  }

  .family-memberships {
    // height: 530px;
    position: relative;
    z-index: 1;
    // background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/bundle.png");
    background-repeat: no-repeat;
    background-position: right top;
    // background-size: 60%;
    margin: 100px 0;

    @media only screen and (max-width: 430px) {
      height: 675px;
      display: block;
      margin-top: 50px;
    }

    // &::after{
    // 	content: 'bundle and save 12%';
    // 	position: absolute;
    // 	color: white;
    // 	bottom: 50px;
    // 	right: 0px;
    // }

    &::before {
      content: "";
      position: absolute;
      width: 65%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0); /* For IE8 and earlier */
      // background: #779b61;
      z-index: -1;
      left: 35%;
    }

    .hero-left:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -65%;
      width: 120%;
      height: 120%;
      background: #106bb5 !important;
      transform: skew(-28deg, 0deg);
      z-index: -1;
      margin-top: -50px;

      @media only screen and (max-width: 430px) {
        transform: scaleX(2);
        margin-top: 0px;
        height: 110%;
      }
    }

    p {
      color: var(--white);
      font-size: rem-calc(20) !important;
      padding: 0 20px;
    }

    .fam-packages {
      padding-top: 10px;
    }

    span {
      font-size: rem-calc(67);
      color: var(--white);
      font-style: italic;
      font-weight: 600;
    }

    .line-one {
      position: relative;
    }

    .line-two {
      position: absolute;
      top: 70px;
      left: 70px;
      width: 120%;

      @media only screen and (min-width: 992px) {
        left: 25px;
        width: 109%;
      }

      //    @media only screen and (max-width: 1081px) {
      // 	left: 60px;
      // 	 width: 106%;
      // }
    }
  }

  .family-mobile-width {
    max-width: initial;
    margin: 0;
    padding: 0;
  }

  .family-memberships-mobile-top {
    // background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/bundle.png");
    background-repeat: no-repeat;
    height: 100%;
    color: var(--white);
    text-align: center;
    font-size: 67px;
    font-style: italic;
    font-weight: 900;
    padding: 50px 0;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      filter: alpha(opacity=80); /* For IE8 and earlier */
      background: var(--secondary);
      z-index: -1;
      left: 0;
      top: 0;
    }

    @media only screen and (max-width: 576px) {
      font-size: rem-calc(46);
    }
  }

  .family-memberships-mobile-bottom {
    background-color: var(--primary);
    height: 100%;
    padding: 30px 30px 50px;
    margin-top: 0;
    color: var(--white);

    p {
      color: var(--white);
      font-size: rem-calc(20) !important;
    }
  }

  .scroll {
    color: var(--primary);
    font-size: rem-calc(18);

    @media only screen and (max-width: 768px) {
      margin: 175px 0 25px;
    }
    @media only screen and (max-width: 576px) {
      margin: 40px 0 0;
    }

    .fa-chevron-down {
      font-size: rem-calc(33);
    }
  }

  .section-2 {
    background: var(--white);
    height: 700px;
    z-index: -2;
  }

  .subtitle {
    font-size: rem-calc(25);
    font-style: italic;
    font-weight: 300;
    padding-bottom: 15px;
  }

  .gray-bg {
    background-color: var(--light-gray);
  }

  .package-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 140px;
    margin-top: 50px;
    background: var(--white);
    border-radius: 5px 5px;

    @media only screen and (max-width: 575px) {
      width: 233px !important;
      margin: 25px auto;
      display: block;
    }

    @media only screen and (max-width: 768px) {
      width: 112px;
    }

    @media only screen and (min-width: 992px) {
      width: 210px;
    }
  }

  .package-name {
    position: relative;
    color: var(--white);
    margin-top: -20px;
    margin-bottom: -15px;
    font-size: 17px;
    font-style: italic;
    font-weight: 900;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 30%;
      width: 42%;
      height: 21px;
      background: var(--primary);
      transform: skew(-28deg, 0deg);
      z-index: -1;

      @media only screen and (max-width: 768px) {
        left: 24%;
        width: 53%;
      }
      @media only screen and (max-width: 575px) {
        top: 0px;
      }
    }

    &__lp {
      display: block;
      margin: 8px auto;
      text-transform: uppercase;
      color: var(--white);
      font-weight: bold;
      font-size: rem-calc(14);
    }
  }

  .package-name2 {
    position: relative;
    color: var(--white);
    margin-top: -20px;
    margin-bottom: -15px;
    font-size: 17px;
    font-style: italic;
    font-weight: 900;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 43%;
      width: 15%;
      height: 21px;
      background: var(--primary);
      transform: skew(-28deg, 0deg);
      z-index: -1;

      @media only screen and (max-width: 575px) {
        top: 0px;
        left: 31%;
        width: 40%;
      }
    }

    &__lp {
      display: block;
      margin: 8px auto;
      text-transform: uppercase;
      color: var(--white);
      font-weight: bold;
      font-size: rem-calc(14);
    }
  }

  .package-name-locations {
    position: relative;
    color: var(--white);
    margin-top: -20px;
    margin-bottom: 30px;
    font-size: 17px;
    font-style: italic;
    font-weight: 900;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: -45%;
      width: 200%;
      height: 30px;
      background: var(--primary);
      transform: skew(-28deg, 0deg);
      z-index: -1;
    }
  }

  .package-card-bestvalue {
    border: 4px solid var(--primary) !important;
    //border-bottom: 30px solid var(--primary) !important;

    &::after {
      content: "the best value.";
      position: absolute;
      color: var(--primary);
      bottom: -25px;
      left: 25%;
      text-transform: capitalize;
      font-weight: bold;
      font-size: rem-calc(14);
      letter-spacing: 1px;
      // background-color: var(--primary);

      // @media screen and (max-width: 991px) {
      //   letter-spacing: 0px;
      //   font-size: 14px;
      //   left: 15%;
      //   bottom: -30px;
      // }
      // @media only screen and (max-width: 768px) {
      //   left: 10%;
      //   font-size: 12px;
      // }
      // @media only screen and (max-width: 575px) {
      //   left: 27% !important;
      //   font-size: 16px !important;
      // }

      // @media only screen and (max-width: 577px) {
      //   top: 288px !important;
      // }
    }
  }
  .package-card-bestvalue-home {
    border: 4px solid var(--primary) !important;
    //border-bottom: 30px solid var(--primary) !important;

    &::after {
      content: "the best value.";
      position: absolute;
      color: var(--primary);
      bottom: -25px;
      left: 25%;
      text-transform: capitalize;
      font-weight: bold;
      font-size: rem-calc(14);
      letter-spacing: 1px;
      // background-color: var(--primary);

      @media screen and (max-width: 991px) {
        letter-spacing: 0px;
        font-size: 14px;
        left: 15%;
        bottom: -30px;
      }
      @media only screen and (max-width: 768px) {
        left: 10%;
        font-size: 12px;
      }
      @media only screen and (max-width: 575px) {
        left: 27% !important;
        font-size: 16px !important;
      }

      @media only screen and (max-width: 577px) {
        top: 288px !important;
      }
    }
  }

  .studio-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 170px;
    margin-top: 50px;
    background: var(--white);
    border-radius: 5px 5px;
  }

  .package-locations {
    // background: #659f410d;
    font-size: rem-calc(14);
    display: inline;

    .check {
      color: var(--secondary);
      // position: absolute;
      display: inline;

      @media only screen and (max-width: 575px) {
        font-size: 18px !important;
        // left: 58px!important;
        // top: 18px!important;
      }

      @media only screen and (max-width: 768px) {
        font-size: 13px;
        // left: 9px;
        // top: 19px;
      }
      @media only screen and (min-width: 992px) {
        // left: 65px;
      }
    }
    .check-2 {
      color: var(--secondary);
      display: inline;

      @media only screen and (max-width: 575px) {
        font-size: 18px !important;
        // left: 58px !important;
        // top: 63px !important;
      }

      @media only screen and (min-width: 992px) {
        // left: 45px;
      }
    }
  }

  .package-tag-locations {
    font-size: rem-calc(14);
    // margin-top: -5px;
    // margin-left: 25px;
    font-weight: 300;

    &__2 {
      margin-left: 45px;
      font-weight: 300;
    }
  }

  .package-price-amount {
    font-weight: 800;
    font-size: 51px;
    font-style: italic;
    margin: 15px 0 0;

    @media only screen and (max-width: 575px) {
      font-size: 42px !important;
    }

    @media only screen and (max-width: 768px) {
      font-size: 40px;
    }
  }

  .package-total-locations {
    font-weight: 800;
    font-size: rem-calc(14);
    display: inline;
    margin-left: 10px;

    @media only screen and (max-width: 575px) {
      font-size: 20px !important;
    }

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .package-tag-permonth {
    margin-top: -15px;
    font-style: italic;
  }

  .package-explore {
    font-weight: bold;
    font-size: rem-calc(12);
    color: var(--primary);
  }

  .inline {
    display: inline !important;
  }

  .especial-margin-img {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 25%;
      left: -3%;
      width: 78%;
      height: 81%;
      opacity: 0.8;
      filter: alpha(opacity=80); /* For IE8 and earlier */
      background: var(--secondary);
      transform: skew(-32deg, 0deg);
      z-index: -1;
    }
  }

  .locations {
    text-align: left;
    // .fa-map-marker-alt{
    // 	color: var(--primary);
    // 	margin-top: 15px;
    //     margin-left: 22px;
    // }

    &_border {
      border: 1px solid var(--white);
      padding: 20px;
    }

    &_name {
      text-align: left;
      color: var(--text);
      font-size: rem-calc(15);
      font-weight: 600;
    }

    &_distance {
      text-align: left;
      color: var(--text);
      font-size: rem-calc(12);
      font-weight: 400;
      font-style: italic;
    }

    &_explore_more {
      margin-top: 10px;
    }
  }
  .enroll {
    p {
      font-size: rem-calc(16) !important;
      font-weight: 400;
      padding: 0 7px;
    }
  }

  .center-btn {
    margin: 0 auto;
    display: block;
  }

  .icon-holder {
    position: relative;

    .fa-id-card {
      width: 200px;
      height: 55px;
      color: white;
      position: absolute;
      left: 75px;
      top: 15px;
    }

    .fa-user-check {
      width: 200px;
      height: 55px;
      color: white;
      position: absolute;
      left: 70px;
      top: 3px;
    }

    .fa-dumbbell {
      width: 200px;
      height: 55px;
      color: white;
      position: absolute;
      left: 70px;
      top: 15px;
    }
  }
  .quote {
    // background: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/quote-hero.png") no-repeat;
    // background-size: cover;
    // height: 600px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 290px;
    }

    img {
      width: 100%;
    }

    .quote-text {
      background: #ffffffe6;
      padding: 50px 30px 10px 100px;
      position: absolute;
      //top: -50px;
      top: 0;
      max-width: 1200px;
      margin-left: 100px;
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    .fa-quote-left {
      font-size: 80px;
      color: var(--primary);
      padding-right: 10px;
    }

    .green {
      &::before {
        content: "";
        position: absolute;
        top: -70px;
        right: 102%;
        width: 60%;
        height: 135%;
        background: var(--secondary);
        transform: skew(-28deg, 0deg);
        z-index: 1;

        @media only screen and (max-width: 992px) {
          right: 125%;
        }

        @media only screen and (max-width: 768px) {
          top: -130px;
          right: 97%;
          width: 50%;
          height: 80%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: -35px;
        right: 99%;
        width: 50%;
        height: 142%;
        background: var(--secondary);
        transform: skew(-28deg, 0deg);
        z-index: 0;
        opacity: 0.8;

        @media only screen and (max-width: 992px) {
          right: 123%;
        }

        @media only screen and (max-width: 768px) {
          top: -64px;
          right: 97%;
          width: 20%;
          height: 63%;
        }
      }
    }

    p {
      font-size: rem-calc(18) !important;
    }
  }

  .last-section {
    background-color: var(--primary);
    color: var(--white);
    padding-bottom: 20px;
    padding-top: 50px;

    h2 {
      font-size: rem-calc(40);
      color: var(--white);
    }
    p {
      font-size: rem-calc(25) !important;
      color: var(--white);
    }
  }

  footer {
    background-color: var(--primary);
    color: var(--white);
    width: 100%;
    flex-shrink: 0;
    min-height: 40%;
    // position: absolute;
    // bottom:0;

    p {
      font-size: rem-calc(14) !important;
      color: var(--white);
    }

    a {
      color: var(--white);
      line-height: 3;
      font-size: rem-calc(16);

      &:hover {
        color: var(--white);
      }
    }
  }

  /* Location Search */

  .location-search {
    min-height: calc(100vh - 75px);
  }

  .map {
    height: calc(100% - 70px);
  }

  .px-4.py-3 .btn-group-toggle .btn.btn-primary.active {
    border-color: white !important;
  }

  .border-primary.border-bottom {
    border-bottom-width: 4px !important;
  }

  button.rounded-circle {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 992px) {
    .border-right {
      border: 0 !important;
    }
    body > nav.shadow {
      box-shadow: none !important;
    }
  }

  @media (min-width: 1366px) {
    .location-search__sidebar {
      max-width: 440px;
    }
  }

  /* just to make it work -> for now */

  .row {
    margin: 0 0;
  }

  .no-margin {
    margin: 0 0 !important;
  }

  .no-padding {
    padding: 0 0;
  }

  .radio-btn-fixed {
    display: inline;
    white-space: nowrap;
  }

  .w-85 {
    width: 85% !important;
  }

  .map-fields {
    &__top {
      z-index: 2;
      right: 70px;
    }

    &__bottom {
      z-index: 1;
      right: 70px;
    }
  }

  .breadcrumb-alignment {
    margin: 0 0 -15px;
  }

  .breadcrumb-item.active {
    color: var(--primary);
    font-weight: 600;
  }

  .breadcrumb-item + .breadcrumb-item {
    @media only screen and (max-width: 508px) {
      padding-left: 0.2rem !important;
    }
  }
  .breadcrumb-item {
    &::before {
      display: none;
    }
  }

  .account-nav-item {
    color: var(--text);
    font-weight: 400;
  }

  .account-nav-item.active {
    color: var(--primary);
    font-weight: 600;
  }

  .enroll-chevron {
    padding-left: 10px;
  }

  .fa-chevron-right {
    padding-top: 2px;
  }

  .map2 {
    margin: 0;
    padding: 0;
    min-width: 41%;
    min-height: auto;
  }

  label.card.active {
    border: 4px solid var(--primary) !important;
    // background-color: #006dc6 !important;
    // color: var(--white);

    // p{
    // 	color: var(--white) !important;
    // }
  }

  .border.border-secondary {
    border-color: var(--secondary) !important;
  }

  .blue-diagonal {
    &::before {
      content: "";
      width: 245px;
      height: 100%;
      position: absolute;
      right: 80px;
      top: 0px;
      background: var(--primary);
      z-index: -1;
      -webkit-transform: skew(-28deg, 0deg);
      transform: skew(-28deg, 0deg);

      @media only screen and (max-width: 1290px) {
        width: 160px;
      }

      @media only screen and (max-width: 1130px) {
        display: none;
      }
    }

    &::after {
      content: "";
      width: 145px;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      background: var(--primary);
      z-index: -1;
      border-radius: 5px;

      @media only screen and (max-width: 1130px) {
        width: 210px;
      }

      @media only screen and (max-width: 840px) {
        width: 40%;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  .gray-diagonal {
    &::before {
      content: "";
      width: 245px;
      height: 100%;
      position: absolute;
      right: 15px;
      top: 0px;
      background: var(--light-gray);
      z-index: -1;
      -webkit-transform: skew(-28deg, 0deg);
      transform: skew(-28deg, 0deg);
    }
    &::after {
      content: "";
      width: 145px;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      background: var(--light-gray);
      z-index: -1;
      border-radius: 5px;
    }
  }

  .btn-right {
    position: absolute;
    top: -15px;
    right: 0;
  }

  .total-small {
    font-size: 12px;
    padding-right: 7px;
  }

  .total-cost {
    font-weight: 600;
    font-size: 28px;
  }

  .btn-secondary:hover {
    color: var(--white);
    background-color: var(--primary);
  }
  .btn-secondary {
    border-color: var(--primary) !important;
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    border-color: #6c757d !important;
  }
  .btn-outline-primary {
    // @include button-bgvar(--white);
    background-color: var(--white);
    color: var(--landingPageButton);
    border-color: var(--landingPageButton);
  }
  .btn-outline-manage-plan {
    background-color: var(--white);
    color: var(--managePlanButton);
    border-color: var(--managePlanButton);
  }
  #btn-add-payment {
    --button-width: none;
  }
  .btn-outline-secondary {
    // @include button-bgvar(--white);
    background-color: var(--white);
  }

  .btn-outline-primary:hover {
    opacity: 1 !important;
  }

  .btn-outline-secondary:hover {
    opacity: 1 !important;
  }

  .select-fam-mem {
    border: 1px solid var(--light-gray);
    padding: 5px;
    margin: 0 0 10px;
    border-radius: 0.25rem;
  }
  .select-fam-mem.active {
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  .form-check-input {
    position: relative !important;
    margin: 0 20px 0 10px;
  }

  .form-check-input-members {
    position: relative !important;
    margin: 0 5px 0 -20px;
  }

  .sticky-top {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
    z-index: 1001;
  }

  div.active::after {
    content: "" !important;
  }

  .modal-title {
    position: relative;
  }

  .modal-close {
    position: absolute;
    right: 0px;
    top: -8px;
  }

  .breadcrumb-border {
    border-bottom: 1px solid var(--light-gray);
  }

  .fixed-h {
    height: 687px;
  }

  .blue-gradient {
    // background-image: linear-gradient(-90deg, #006DC6, #D3FFFF);
    // background-color: #2070a0;
    background-color: var(--primary);
  }

  .btn-especial {
    border: 1px solid var(--white);
    background-color: transparent;
    color: var(--white);
    border-radius: 5px;
    padding: 7px 30px;
    margin: 20px 0 25px;
  }

  .line-br {
    margin: 35px auto;
    display: block;
    border-top: 2px solid var(--light-gray);
    max-width: 650px;
  }

  .circle {
    width: 25px;
    height: 25px;
    background-color: var(--white);
    border: 1px solid var(--primary);
    border-radius: 50%;
    position: relative;
    margin: 0 20px 0;
  }

  .circle-num {
    color: var(--primary);
    position: absolute;
    top: -2px;
    left: 30%;
    font-weight: 600;
  }

  .vertical-line {
    width: 1px;
    height: 20px;
    background: var(--primary);
    margin: 20px auto 0 auto;
    position: absolute;
    left: 33px;
    top: 10px;

    @media only screen and (max-width: 768px) {
      height: 50px;
    }
  }

  .vertical-line-2 {
    width: 1px;
    height: 20px;
    background: var(--primary);
    margin: 20px auto 0 auto;
    position: absolute;
    left: 33px;
    top: 10px;

    @media only screen and (max-width: 768px) {
      height: 80px;
      top: 27px;
    }
  }

  .vertical-line-3 {
    width: 1px;
    height: 30px;
    background: var(--primary);
    margin: 20px auto 0 auto;
    position: absolute;
    left: 33px;
    top: 10px;

    @media only screen and (max-width: 768px) {
      height: 80px;
      top: 27px;
    }
  }
  .select-fam-mem {
    // border: 1px solid var(--light-gray);
    padding: 5px;
    margin: 0 0 10px;
    border-radius: 0.25rem;
  }

  .table-center {
    margin: 0 auto;
  }

  .table-space {
    border-spacing: 0px 15px;
    border-collapse: separate;
    line-height: 35px;
  }

  .position-relative {
    position: relative;
  }

  .floating-txt {
    position: absolute;
    right: 0;
    top: 150px;
  }

  .fixed-bottom {
    position: sticky !important;
  }

  .lp-list {
    li {
      list-style: disc;
    }
  }

  .btn-primary-disable {
    color: var(--primary);
    background-color: var(--light-gray);
  }

  .btn-primary-disable:hover {
    color: var(--primary) !important;
  }

  /*-- Location Number Dots --*/
  .location-number::before {
    content: ".";
    font-size: 72px;
    line-height: 0;
    position: relative;
    bottom: 5px;
    color: var(--primary);
  }

  .just-location-number {
    font-size: 1.5rem !important;
    line-height: 0;
    color: var(--text);
    padding-bottom: 20px;
  }

  .studio-number::before {
    content: ".";
    font-size: 72px;
    line-height: 0;
    position: relative;
    bottom: 5px;
    color: var(--orange);
  }

  .studio-list-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 30vh;
  }

  .location-details-list-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
  }

  .location-list-scroll:hover,
  .studio-list-scroll:hover,
  .location-details-list-scroll:hover {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--white);
      border-radius: 8px;
    }
  }

  .square {
    &__top {
      background-color: var(--primary);
      width: 150px;
      padding: 30px;
      margin: 0 auto;
      position: relative;

      @media only screen and (max-width: 768px) {
        width: 117px;
        padding: 25px;
      }

      @media only screen and (max-width: 576px) {
        width: 97px;
        padding: 25px;
      }

      &::before {
        content: "";
        width: 205px;
        height: 100%;
        position: absolute;
        right: -27px;
        top: 0px;
        background: var(--primary);
        transform: skew(-28deg, 0deg);
        z-index: 1;

        @media only screen and (max-width: 768px) {
          width: 163px;
          right: -23px;
        }

        @media only screen and (max-width: 576px) {
          width: 140px;
          right: -19px;
        }
      }

      &::after {
        content: "";
        width: 203px;
        height: 100%;
        position: absolute;
        right: -37px;
        top: 10px;
        background: var(--secondary);
        transform: skew(-28deg, 0deg);

        @media only screen and (max-width: 768px) {
          width: 155px;
          right: -32px;
          top: 12px;
        }

        @media only screen and (max-width: 576px) {
          width: 135px;
          right: -26px;
          top: 10px;
        }
      }

      img {
        z-index: 20;
        position: relative;
        max-width: 100%;
      }
    }

    &__bottom {
      background-color: var(--secondary);
      width: 60%;
      padding: 30px;
      margin: 0 auto;
    }
  }

  .loading-spinner-locations {
    position: "absolute";
    top: 30%;
    right: 28%;
    margin: 0 auto;
    display: block;
    width: 200px;
  }

  .loading-spinner-map {
    margin: 30% auto;
    display: block;
    width: 150px;
  }

  .loading-spinner-payment {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .loading-spinner-left {
    // position: absolute;
    // top: 50%;
    // right: 40%;
    padding-top: 10px;
    margin: 0px auto;
    display: block;
    width: 100px;
  }

  .left-content {
    overflow-y: visible;
    padding-top: 9px;
  }

  /*-- Best Value Tag Control --*/
  .badge {
    top: -10px;
    z-index: 998;
  }

  .mobile-action-bar {
    height: 120px;
  }

  .primary-bg {
    background-color: var(--primary);
  }

  .fixed-bottom {
    @media only screen and (max-width: 768px) {
      height: 130px;
    }
  }

  .mobile-card {
    @media only screen and (max-width: 768px) {
      border: 1px solid #ddd;
      color: var(--text);
      border-radius: 5px;
      width: 47%;
      margin: 0 4px;
      position: relative;
    }
  }

  label.mobile-card.active {
    background-color: var(--primary) !important;
    color: var(--white);

    p {
      color: var(--white) !important;
    }
  }

  .punch-card {
    max-width: 350px;
  }

  .sign-up-footer {
    bottom: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 25px 0;
    margin-top: 20px;
    position: sticky;
    z-index: 3;
    @media screen and (max-width: 576px) {
      position: fixed;
    }
  }

  .edit-plans-footer {
    bottom: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 25px 0;
    margin-top: 20px;
  }

  .forced-height {
    min-height: 450px;
  }

  .member-card {
    border-color: #ddd;
    color: var(--text);
    border-radius: 5px 5px 0 0;
    transition: box-shadow 0.15s ease-in-out;

    &__bottom {
      border: 1px solid #dee2e6;
      border-radius: 0 0 5px 5px;
    }
  }

  .app-phone-img {
    display: block;
    margin: 0 auto;
  }

  .location-pin-lp {
    width: 12px;
    position: absolute;
    top: -10px;
  }

  .discs {
    li {
      list-style: disc;
    }
  }

  .react-datepicker__input-container {
    input {
      color: #666;
      border-radius: 5px;
      border: 1px solid #ddd;
      padding-left: 5px;
    }
  }

  .lp {
    p {
      font-size: rem-calc(16) !important;
    }
  }

  .studio-classes {
    p {
      font-size: rem-calc(16) !important;
    }
  }

  .btn-group-toggle {
    .btn {
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--primary) !important;

      &.active {
        color: var(--white);
      }
    }
  }

  .custom-radio-sup {
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      position: relative;
      background-color: var(--white);
      color: var(--primary);
      height: 21px;
      width: 21px;
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      margin-right: -10px;
      outline: none;
      border: 3px solid var(--primary);

      &:checked {
        background-color: var(--primary);
      }
    }

    .custom-span {
      background-color: var(--primary);
      font-size: 14px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -6px;
        width: 112%;
        height: 24px;
        background: var(--primary);
        -webkit-transform: skew(-28deg, 0deg);
        transform: skew(-28deg, 0deg);
        z-index: -1;
      }
    }

    .fa-check {
      color: var(--gray);
      font-size: 10px;
      font-weight: bold;
      margin: 0 10px 0 0;
    }

    &.active {
      span {
        background-color: var(--primary);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -6px;
          width: 112%;
          height: 24px;
          background: var(--primary);
          -webkit-transform: skew(-28deg, 0deg);
          transform: skew(-28deg, 0deg);
          z-index: -1;
        }
      }

      .fa-check {
        color: var(--secondary);
      }
    }
  }

  .custom-radio-inf {
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      position: relative;
      background-color: var(--white);
      color: var(--primary);
      top: 30px;
      right: 15px;
      height: 21px;
      width: 21px;
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      margin-right: -10px;
      outline: none;
      border: 3px solid var(--primary);

      &:checked {
        background-color: var(--primary);
      }
    }
  }

  .plus-gyms {
    text-align: left;
    font-weight: bold;
    font-size: rem-calc(14);

    .fa-check {
      color: var(--gray);
      font-size: 10px;
      font-weight: bold;
      margin-right: 5px;
    }
  }

  .price-block {
    display: block;
    margin: 0 auto;
  }

  .price {
    font-size: rem-calc(50);
    font-weight: 800;
    font-style: italic;
    position: relative;
    @media only screen and (max-width: 1300px) {
      font-size: rem-calc(44);
    }
  }

  .per-month {
    font-size: rem-calc(12);
    padding-left: 5px;
    margin-bottom: 5px;
    text-align: center;
    // position: absolute;
    //    right: 40px;
    //    top: 63px;
  }

  .card-break-line {
    border-top: 1px solid var(--gray);
    width: 100%;
    // margin-left: -16px;
    background-color: #f8f8f8;
    font-size: 14px;
    padding: 10px 5px;
  }

  .card-break-line-wm {
    width: 100%;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 10px 5px;
  }

  .card-break-line-wm2 {
    border: 1px solid #0000001f;
    width: 100%;
    background-color: #f8f8f8;
    font-size: 14px;
    padding: 10px 5px;
  }

  .per-month-wm {
    font-style: italic;
    font-size: rem-calc(12);
    padding-left: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .cbl {
    border-top: 1px solid var(--gray);
    width: 100%;
    // margin-left: -16px;
    background-color: #f8f8f8;
    font-size: 14px;
    padding: 10px 5px;
  }

  .sp-des {
    font-size: rem-calc(12);

    .offer {
      font-size: rem-calc(14);
      font-weight: bold;
    }

    .fa-check {
      vertical-align: top;
    }
  }

  .flex-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-column-gap: 3%;
    grid-row-gap: 8%;

    @media only screen and (max-width: 401px) {
      grid-row-gap: 3%;
      margin-bottom: 60px;
    }
  }

  .location-amenities-hr {
    border: 2px solid var(--primary);
  }

  .amenities-list {
    ul {
      li {
        padding: 1rem 0;
        border-bottom: 1px solid #0000001a;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .amenities-chevron {
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: rem-calc(22);
    color: var(--secondary);
  }

  .pagination {
    a {
      cursor: pointer;
    }
  }

  .location-count {
    min-width: 100px;
  }

  .primary-color {
    color: var(--primary);
  }

  .secondary-color {
    color: var(--secondary);
  }

  .pointer {
    cursor: pointer;
  }

  .error {
    font-size: rem-calc(180) !important;
    font-weight: 300 !important;
  }

  .page-not-found {
    font-size: rem-calc(24);
    font-weight: bold;
    color: var(--text);
  }

  .border-elite {
    border: 1px solid var(--secondary);
  }

  .standard-gyms-pin {
    .fa-map-marker-alt {
      color: var(--primary);
    }
  }

  .elite-gyms-pin {
    .fa-map-marker-alt {
      color: var(--secondary);
    }
  }

  .overlay {
    //height: 200vh;
    width: 100vw;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .faq {
    p {
      font-size: 14px;
    }

    .h3 {
      font-size: 25px;
      font-weight: bold;
    }

    button {
      color: var(--primary);
      background: var(--white);
      border: 1px solid #ddd;
      border-radius: 4px 4px 0px 0px;
      padding: 20px 18px;
      width: 100%;
      text-align: left;
    }

    .panel {
      background: var(--light-gray);
      border: 1px solid #ddd;
      border-top: none;
      border-radius: 0px 0px 4px 4px;
      padding: 20px 18px;
    }
  }

  .tier_description {
    font-size: 0.875rem;
    display: inline;
    margin-left: 10px;

    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      color: var(--secondary);
      display: inline;
      font-weight: 800;
      margin-right: 5px;
    }

    b {
      padding-left: 5px;
    }
  }

  .calc-fontsize {
    font-size: 1rem;
    @media screen and (max-width: 650px) {
      font-size: rem-calc(14);
    }
    @media screen and (max-width: 510px) {
      font-size: rem-calc(10);
    }
  }

  .upcoming-paymenttext {
    @media screen and (max-width: 321px) {
      font-size: 0.9rem;
    }
  }

  .best-value-card::after {
    content: "the best value.";
    position: absolute;
    color: var(--primary);
    bottom: -25px;
    left: 25%;
    text-transform: capitalize;
    font-style: italic;
    font-weight: bold;
    font-size: rem-calc(14);
    letter-spacing: 1px;
    @media screen and (max-width: 1550px) {
      left: 13%;
      font-size: rem-calc(13);
    }
    @media screen and (max-width: 1350px) {
      left: 11%;
      font-size: rem-calc(11);
    }
    @media screen and (max-width: 1199px) {
      left: 16%;
    }
    @media screen and (max-width: 1162px) {
      left: 14%;
      font-size: rem-calc(13);
      letter-spacing: 0px;
    }
    @media screen and (max-width: 991px) {
      letter-spacing: 0px;
      font-size: rem-calc(14);
      left: 25%;
      bottom: -30px;
    }
    @media only screen and (max-width: 768px) {
      left: 27%;
      font-size: rem-calc(14);
    }
    @media only screen and (max-width: 634px) {
      bottom: -27px;
      left: 24%;
    }
    @media only screen and (max-width: 621px) {
      bottom: -27px;
      left: 24%;
    }
    @media only screen and (max-width: 604px) {
      bottom: -27px;
      left: 32%;
    }
    @media only screen and (max-width: 575px) {
      left: 32%;
      bottom: -27px;
      font-size: rem-calc(16);
    }
    @media only screen and (max-width: 482px) {
      left: 27%;
    }
    @media only screen and (max-width: 435px) {
      left: 23%;
    }
    @media only screen and (max-width: 435px) {
      left: 33%;
    }
  }

  .flex-basis-auto {
    flex-basis: auto !important;
  }

  .fixed-mobile-navbar {
    position: fixed;
    left: 0;
    opacity: 1;
    width: 100vw;
    top: 75px;
    z-index: 1001;
  }
  // ----------------- responsiveness for manage dependents --------------------------- \\
  .btn-minwidth {
    @media screen and (max-width: 593px) {
      min-width: 140px;
    }
  }
  // ---------------- end of responsiveness for manage dependents --------------------- \\

  .view-account {
    &__button {
      position: relative;
    }

    &__chevron {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 17px;
      right: 8px;
    }
  }

  .tb-spaceing {
    td {
      line-height: 1.7;
    }
  }

  .w-btn-confirmation {
    width: 50%;

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .confirmation {
    a {
      text-decoration: underline;
    }
  }
  // -----------------------  styles for the empty lists ------------------------------- \\
  .height-500 {
    height: 500px;
  }
  .height-250 {
    height: 250px !important;
  }

  .height-150 {
    height: 150px !important;
  }
  // -------------------- end of styles for the empty lists ------------------------------- \\

  // ============== FILL COLORS BY CLIENT ============== \\
  .fill-primary {
    fill: var(--primary);
  }

  .fill-secondary {
    fill: var(--secondary);
  }

  .fill-white {
    fill: var(--white);
  }
  // ============== END FILL COLORS BY CLIENT ============== \\

  .text-underline-primary {
    text-decoration: underline var(--primary);
  }

  .text-sm-center {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
  }

  .w-sm-100 {
    @media screen and (max-width: 576px) {
      width: 100% !important;
    }
  }
  .w-md-100 {
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  .w-lg-100 {
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
  }
  .w-xl-100 {
    @media screen and (max-width: 1200px) {
      width: 100% !important;
    }
  }

  .logout-logo {
    width: 234px;
    height: 41px;
  }

  .text-sm {
    font-size: rem-calc(14);
  }

  .text-lg {
    font-size: rem-calc(20);
  }

  //-------------- PRIMARY CHECKBOX STYLING ---------------\\
  .checkbox-wrapper {
    .checkmark {
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 5px;
      position: relative;
      border: 2px solid var(--primary);
      transition: background-color 0.1s;
      &::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 12px;
        border-right: 3px solid var(--white);
        border-bottom: 3px solid var(--white);
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%) rotateZ(40deg) scale(3);
        opacity: 0;
        transition: all 0.1s;
      }
    }
    input[type="checkbox"]:checked ~ .checkmark {
      background-color: var(--primary);
    }
    input[type="checkbox"]:checked ~ .checkmark::after {
      opacity: 1;
      transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
    }
  }

  //-------------- END OF PRIMARY CHECKBOX STYLING ----------\\

  .elite-tag-loc {
    position: absolute;
    top: 0;
    right: 8px;
  }

  .home-gym-tag-loc {
    position: absolute;
    top: 18px;
    right: 0;
  }

  .bg-primary {
    background-color: var(--primary) !important;
  }
  .slanted-background {
    position: relative;
    background: #f2f2f2;
    height: 100%;
    margin-right: -15px;
    &::after {
      content: "";
      position: absolute;
      top: -15%;
      left: 0;
      width: 100%;
      height: 130%;
      opacity: 0.9;
      filter: alpha(opacity=80); /* For IE8 and earlier */
      background: #f2f2f2;
      transform: skew(-25deg, 0deg);
      transform-origin: 100% 0;
      z-index: -1;
    }
  }

  #submitButton {
    .btn-submit {
      display: none !important;
    }
  }

  .badge-skewed-primary {
    text-transform: uppercase;
    background-color: var(--primary);
    color: var(--white);
    transform: skew(-20deg);
    p {
      transform: skew(20deg);
    }
  }

  .mw-350 {
    max-width: 350px;
  }

  .nowrap-responsive {
    white-space: normal;
    @media screen and (min-width: 576px) {
      white-space: nowrap;
    }
  }

  .next-line > a {
    float: left;
    word-break: break-word;
    height: 100px;
    padding: 15px;
  }

  .line {
    border-top: 1px solid #0000001f;
  }
  .center {
    text-align: center;
  }
  .num-of-gyms {
    margin-bottom: 0 !important;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }
  .nog {
    margin-bottom: 0 !important;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }
  .num-of-gyms-elite {
    margin-bottom: 0 !important;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f3c5";
      color: var(--secondary);
      display: inline;
      font-weight: 800;
      margin-right: 5px;
    }
  }
  .num-des {
    text-align: center;
    font-size: 14px;
  }
  .nd {
    margin: -5px 0 15px;
    text-align: center;
    font-size: 14px;
  }
  .description {
    margin-bottom: 0 !important;
    text-align: center;
  }
  .e-gym {
    border: 1px solid #82b265;
    border-radius: 5px;
    padding: 2px;
    font-size: 14px;
    margin: 3px 5px 15px;
  }

  .transparent-border {
    border: 1px solid transparent;
  }

  .nav-btn-enroll {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    @media only screen and (max-width: 600px) {
      margin: 5px 0 5px 0;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      font-size: 15px !important;
    }
  }

  // .fill {
  //   & var(--primary) {
  //     fill: var(--primary) !important;
  //   }
  //   & var(--secondary) {
  //     fill: var(--secondary) !important;
  //   }
  // }

  .lp-std-gym {
    .num-of-gyms {
      text-align: left;
      // display: inline;
      font-weight: bold;
      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f00c";
        color: var(--secondary);
        display: inline;
        font-weight: 800;
        margin-right: 5px;
      }
    }
    .nog {
      display: block;
      text-align: left;
      font-weight: bold;
      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f00c";
        color: var(--secondary);
        display: inline;
        font-weight: 800;
        margin-right: 5px;
      }
    }
    .num-des {
      display: inline;
    }
    .nd {
      display: inline;
    }
    .card-break-line {
      border-top: none;
      width: 100%;
      background-color: var(--white);
      font-size: 14px;
      padding: 0;
    }
    .cbl {
      border-top: none;
      width: 100%;
      background-color: var(--white);
      font-size: 14px;
      padding: 0;
    }
    .per-month {
      display: none;
    }
    .extra-des {
      display: none;
      margin-bottom: 0px;
      -webkit-align-self: center;
      align-self: center;
      font-size: 13px;
    }
    .num-of-gyms-elite {
      text-align: left;
    }
    .e-gym {
      padding: 10px;
      font-size: 14px;
      margin: 5px 5px 5px -10px;

      .lp-num-of-gyms-elite {
        display: block !important;
      }
    }
    .lp-o {
      display: block;
      margin-bottom: 0;
      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f3c5";
        color: var(--secondary);
        display: inline;
        font-weight: 800;
        margin-right: 5px;
      }
    }
    .sp-o {
      display: inline;
    }
    .sp-oo {
      display: inline;
    }
  }
  .extra-des {
    margin-bottom: 0px;
    -webkit-align-self: center;
    align-self: center;
    font-size: 13px;
  }
  .lp-o {
    display: none;
  }
  .sp-o {
    display: block;
    margin-bottom: 0;
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f3c5";
      color: var(--secondary);
      display: inline;
      font-weight: 800;
      margin-right: 5px;
    }
  }
  .sp-oo {
    display: block;
    margin-bottom: 0;
  }
  .legal-sup {
    font-size: 14px;
    color: #000;
    font-weight: normal;

    sup {
      top: -20px;
    }
  }

  .dependent-msg {
    color: #333;
    font-size: 12px;
    max-width: 54%;
  }
}
