body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.json-selected {
  background-color: rgba(139, 191, 228, 0.19999999999999996);
}

.json-string {
  color: #6caedd;
}

.json-key {
  color: #ec5f67;
}

.json-boolean {
  color: #99c794;
}

.json-number {
  color: #99c794;
}

.flashit {
  color: #000000;
  -webkit-animation: flash linear 1s;
  animation: flash linear 1s;
}

@keyframes flash {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #34f053;
  }
  100% {
    background-color: #ffffff;
  }
}
