.mockpanel {
  position: fixed;
  top: 90px;
  right: 10px;
  width: 20%;
  max-width: 180px;
  padding: 10px;
  z-index: 99999;
  border-radius: 5px;
  background: #f5f5f5;
  cursor: pointer;
  .mock-transparency {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  input {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    z-index: 9;
  }
  label {
    cursor: pointer;
  }
  p {
    font-weight: 600;
    margin-bottom: 0px;
  }
}
