.add-payment-modal {
  &__item {
    margin-bottom: 12px;
    margin-top: 20px;
    margin-left: 8px;
  }

  &__subheader {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    padding-bottom: 4px;
  }

  &__required-field-marker {
    color: #d9534f;
    margin-left: 2px;
  }

  &__form-check-label {
    margin-top: 8px;
    font-size: 13px;
    display: flex;
    align-items: baseline;
  }

  &__zuoraText {
    color: #737373;
    font-size: 12px;
    margin-left: 8px;
    margin-top: -12px;
  }
  &__zuoraButton {
    font-size: 12px;
    background: none;
    border: none;
    color: #737373;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }
}
