$theme-colors: (
  "primary": #bf2000,
);
:root {
  /* Changed Values */
  --blue: var(--primary);
  --primary: var(--primary);
  --secondary: var(--secondary);
  --accent: var(--accent);
  --warning: #ba8319;
  --danger: #d9534f;
  --light: #f5f5f5;
  --gray: #aaa;
  --gray-dark: #666;
  --dark: #333;
  --orange: #eb7834;
  --success: #02815c;

  /* Default Values */
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --danger: #d9534f;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --info: #17a2b8;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", "Segoe UI", "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

@import "node_modules/bootstrap/scss/bootstrap";
@import "./global/app.scss";
@import "./hcsc/app.scss";
@import "./fyw/app.scss";
@import "./additional.scss";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }

    .rule {
      padding: 5px;
    }
  }
}

select option:disabled {
  background-color: #f5f5f5;
  color: #a5a5a5;
}

// option:checked {
//   color: $primary;
// }
