// $primary: #0e2675;
// $secondary: #d0513f;
// $white: #fff;
// $black: #000;
$text: #000000;
$light-gray: #f2f2f2;
$dark-gray: #444444;
$gray: #b4b4b4;
// $green: #82b265;
$platform-gray: #f8f9fa;

@function rem-calc($size) {
  $remSize: calc($size / 16);
  @return #{$remSize}rem;
}

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: $text;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $white;
}

.platform-colors {
  &__bg {
    background-color: $platform-gray;
  }
}

.content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex-grow: 1;
}

header,
main,
footer {
  flex-shrink: 0;
}

h1 {
  @media only screen and (max-width: 576px) {
    font-size: rem-calc(32);
  }
}

h2 {
  @media only screen and (min-width: 576px) {
    // font-size: rem-calc(20);
  }
}

.h2 {
  font-size: 2vw;
}

// ul{
// 	li{
// 		font-weight: 400;
// 		font-size: 20px;
// 		font-family: 'Roboto';
// 		color: $text;
// 	}
// }

.extra-margin-top {
  margin-top: 55px;
}
.extra-margin-bottom {
  margin-bottom: 55px;
}

.extra-padding-bottom {
  padding-bottom: 55px;
}

.overlap-img {
  margin-top: -30px;
}

.white {
  color: $white !important;
}

.blue {
  color: $primary !important;
}

.primary {
  color: $primary !important;
}

.text-dark-gray {
  color: $dark-gray !important;
}

// border danger doesnt turn the background into red so im adding a border danger manually

.border-danger {
  border: 1px solid #dc3435 !important;
}

.blue-btn-primary {
  color: $white;
  background-color: $primary;
  padding: 15px 60px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  font-size: rem-calc(17);
  margin-top: 20px;

  &__locations {
    padding: 8px 25px;
    color: $white;
    background-color: $primary;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-size: rem-calc(17);
    margin-top: 20px;
  }
}

.blue-btn {
  @include button-bg($primary);
  color: $white;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  font-size: 17px;
  &__rounded {
    color: $white;
    background-color: $primary;
    padding: 10px 50px;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    // @media screen and (max-width: 991px) {
    //   padding: 15px 20px !important;
    // }
    // @media screen and (max-width: 768px) {
    //   padding: 10px !important;
    // }
    // @media screen and (max-width: 576px) {
    //   padding: 0.25rem 3rem !important;
    // }
  }
}

.blue-btn-small {
  color: $white;
  background-color: $primary;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.white-btn {
  color: $primary;
  background-color: $white;
  padding: 15px 50px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  font-size: rem-calc(17);
  margin-top: 20px;

  .fa-chevron-right {
    margin-left: 7px;
  }
}

.banner {
  width: 100%;
  padding: 20px;
  background-color: $secondary;
  color: $white;
  font-size: 20px;
  z-index: 1001;
  position: relative;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.section-nav {
  background-color: $white;
}

.navbar-nav {
  .nav-item {
    margin: 0 rem-calc(20);
  }

  .nav-link {
    color: $primary;
  }

  .nav-padding {
    padding-top: 20px;
  }
}

.nav-container {
  position: relative;

  &::before {
    content: "";
    width: 245px;
    height: 100%;
    position: absolute;
    right: 40px;
    top: 0px;
    background: $light-gray;
    z-index: -1;
    transform: skew(-28deg, 0deg);
  }

  &::after {
    content: "";
    width: 100px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background: $light-gray;
    z-index: -1;
  }
}

// .navbar-collapse.show{
// 	background: $white;
// 	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
//     z-index: 10;
// }

.apps {
  img {
    display: inline-block;
  }
}

.helper {
  position: relative;
  img {
    position: absolute;
    right: 105px;
  }
}

.aligns-right {
  float: right;
}

.align-left {
  float: left;
}

.nav-cta-enroll {
  z-index: 1;
}

.section-1 {
  height: 600px;
  position: relative;
  z-index: 1;
  background-color: $primary;
  background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/Header-Image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  @media only screen and (max-width: 768px) {
    background-position: right center;
  }

  @media only screen and (max-width: 576px) {
    height: 275px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 3%;
    width: 50%;
    height: 95%;
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
    background: $secondary;
    transform: skew(-28deg, 0deg);
    z-index: -1;

    @media only screen and (max-width: 769px) {
      top: 75%;
      left: -60%;
      width: 145%;
      height: 50%;
    }

    // @media only screen and (max-width: 768px) {
    // 	top: 78%;
    //     left: 39%;
    //     width: 40%;
    //     height: 47%;
    // }

    @media only screen and (max-width: 576px) {
      top: 480px;
      left: -61%;
      width: 145%;
      height: 45%;
    }

    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .hero-left:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -70%;
    width: 123%;
    height: 100%;
    background: $primary;
    transform: skew(-28deg, 0deg);
    z-index: -1;

    @media only screen and (max-width: 768px) {
      top: 415px;
      left: -70%;
      width: 151%;
      height: 60%;
    }

    @media only screen and (max-width: 576px) {
      top: 0;
      left: -20%;
      width: 36%;
      height: 60%;
    }

    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  p {
    color: $white;
  }

  &__mobile {
    font-size: rem-calc(32);
    font-weight: 600;
  }

  &__mobile-sub {
    font-size: rem-calc(16);
    font-weight: 600;
  }
}

.hero-s1 {
  display: none;

  @media only screen and (max-width: 576px) {
    display: block;
  }
}

.hero-s1-button-div {
  background-color: $white;
  position: fixed;
  bottom: 0;
  z-index: 998;
  width: 100%;
  height: auto;
  padding: 16px 12px;
  margin: 0 auto;
  left: 0;

  @media only screen and (min-width: 577px) {
    display: none;
  }
}

.hero-s1-button {
  display: none;
  width: 100%;
  margin: 25px 0;

  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.lh1 {
  position: relative;

  &__text {
    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 330px;
    }
  }

  h1 {
    @media only screen and (max-width: 768px) {
      font-size: rem-calc(40);
    }
  }

  @media only screen and (max-width: 576px) {
    display: none;
  }
}

.gyms {
  background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/gyms.png");
  background-repeat: no-repeat;
  background-position: right;
  padding: 175px 0 188px;

  @media only screen and (max-width: 768px) {
    background: none;
  }
}

.family-memberships {
  max-height: 465px;
  top: 1px;
  position: absolute;
  z-index: 1;
  // background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/bundle.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 55%;
  margin: 30px 0;

  // &::after{
  // 	position: absolute;
  // 	color: white;
  // 	bottom: 100px;
  // 	right: 0px;
  // }

  &::before {
    content: "";
    position: absolute;
    width: 65%;
    height: 100%;
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
    background: $secondary;
    z-index: -1;
    left: 35%;
  }

  .hero-left:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -65%;
    width: 120%;
    height: 120%;
    background: $primary;
    transform: skew(-28deg, 0deg);
    z-index: -1;
    margin-top: -50px;
  }

  p {
    color: $white;
    font-size: rem-calc(20) !important;
    padding: 0 20px;
  }
  h1 {
    color: $white;
  }

  .fam-packages {
    padding-top: 10px;
  }

  span {
    font-size: rem-calc(67);
    color: $white;
    font-style: italic;
    font-weight: 600;
  }

  .line-one {
    position: relative;
  }

  .line-two {
    position: absolute;
    top: 70px;
    left: 70px;
    width: 120%;

    @media only screen and (min-width: 992px) {
      left: 25px;
      width: 109%;
    }

    //    @media only screen and (max-width: 1081px) {
    // 	left: 60px;
    // 	 width: 106%;
    // }
  }
}

.family-mobile-width {
  max-width: initial;
  margin: 0;
  padding: 0;
}

.family-memberships-mobile-top {
  background-image: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/bundle.png");
  background-repeat: no-repeat;
  height: 100%;
  color: $white;
  text-align: center;
  font-size: 67px;
  font-style: italic;
  font-weight: 900;
  padding: 50px 0;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
    background: $secondary;
    z-index: -1;
    left: 0;
    top: 0;
  }

  @media only screen and (max-width: 576px) {
    font-size: rem-calc(46);
  }
}

.family-memberships-mobile-bottom {
  background-color: $primary;
  height: 100%;
  padding: 30px 30px 50px;
  margin-top: 0;
  color: $white;

  p {
    color: $white;
    font-size: rem-calc(20) !important;
  }
}

.scroll {
  color: $primary;
  font-size: rem-calc(18);

  @media only screen and (max-width: 768px) {
    margin: 175px 0 25px;
  }
  @media only screen and (max-width: 576px) {
    margin: 40px 0 0;
  }

  .fa-chevron-down {
    font-size: rem-calc(33);
  }
}

.section-2 {
  background: $white;
  height: 700px;
  z-index: -2;
}

.subtitle {
  font-size: rem-calc(25);
  font-style: italic;
  font-weight: 300;
  padding-bottom: 15px;
}

.gray-bg {
  background-color: $light-gray;
}

.package-card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 140px;
  margin-top: 50px;
  background: $white;
  border-radius: 5px 5px;

  @media only screen and (max-width: 575px) {
    width: 233px !important;
    margin: 25px auto;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 112px;
  }

  @media only screen and (min-width: 992px) {
    width: 210px;
  }
}

.package-name {
  position: relative;
  color: $white;
  margin-top: -20px;
  margin-bottom: -15px;
  font-size: 17px;
  font-style: italic;
  font-weight: 900;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 30%;
    width: 42%;
    height: 21px;
    background: $primary;
    transform: skew(-28deg, 0deg);
    z-index: -1;

    @media only screen and (max-width: 768px) {
      left: 24%;
      width: 53%;
    }
    @media only screen and (max-width: 575px) {
      top: 0px;
    }
  }

  &__lp {
    display: block;
    margin: 8px auto;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    font-size: rem-calc(14);
  }
}

.package-name2 {
  position: relative;
  color: $white;
  margin-top: -20px;
  margin-bottom: -15px;
  font-size: 17px;
  font-style: italic;
  font-weight: 900;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 43%;
    width: 15%;
    height: 21px;
    background: $primary;
    transform: skew(-28deg, 0deg);
    z-index: -1;

    @media only screen and (max-width: 575px) {
      top: 0px;
      left: 31%;
      width: 40%;
    }
  }

  &__lp {
    display: block;
    margin: 8px auto;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    font-size: rem-calc(14);
  }
}

.package-name-locations {
  position: relative;
  color: $white;
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: 17px;
  font-style: italic;
  font-weight: 900;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -45%;
    width: 200%;
    height: 30px;
    background: $primary;
    transform: skew(-28deg, 0deg);
    z-index: -1;
  }
}

.package-card-bestvalue {
  border: 4px solid $primary !important;
  //border-bottom: 30px solid $primary !important;

  &::after {
    content: "the best value.";
    position: absolute;
    color: $primary;
    bottom: -25px;
    left: 25%;
    text-transform: capitalize;
    font-weight: bold;
    font-size: rem-calc(14);
    letter-spacing: 1px;
    // background-color: $primary;

    // @media screen and (max-width: 991px) {
    //   letter-spacing: 0px;
    //   font-size: 14px;
    //   left: 15%;
    //   bottom: -30px;
    // }
    // @media only screen and (max-width: 768px) {
    //   left: 10%;
    //   font-size: 12px;
    // }
    // @media only screen and (max-width: 575px) {
    //   left: 27% !important;
    //   font-size: 16px !important;
    // }

    // @media only screen and (max-width: 577px) {
    //   top: 288px !important;
    // }
  }
}

.studio-card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 170px;
  margin-top: 50px;
  background: $white;
  border-radius: 5px 5px;
}

.package-locations {
  // background: #659f410d;
  font-size: rem-calc(14);
  display: inline;

  .check {
    color: $secondary;
    // position: absolute;
    display: inline;

    @media only screen and (max-width: 575px) {
      font-size: 18px !important;
      // left: 58px!important;
      // top: 18px!important;
    }

    @media only screen and (max-width: 768px) {
      font-size: 13px;
      // left: 9px;
      // top: 19px;
    }
    @media only screen and (min-width: 992px) {
      // left: 65px;
    }
  }
  .check-2 {
    color: $secondary;
    display: inline;

    @media only screen and (max-width: 575px) {
      font-size: 18px !important;
      // left: 58px !important;
      // top: 63px !important;
    }

    @media only screen and (min-width: 992px) {
      // left: 45px;
    }
  }
}

.package-tag-locations {
  font-size: rem-calc(14);
  // margin-top: -5px;
  // margin-left: 25px;
  font-weight: 300;

  &__2 {
    margin-left: 45px;
    font-weight: 300;
  }
}

.package-price-amount {
  font-weight: 800;
  font-size: 51px;
  font-style: italic;
  margin: 15px 0 0;

  @media only screen and (max-width: 575px) {
    font-size: 42px !important;
  }

  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
}

.package-total-locations {
  font-weight: 800;
  font-size: rem-calc(14);
  display: inline;
  margin-left: 10px;

  @media only screen and (max-width: 575px) {
    font-size: 20px !important;
  }

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.package-tag-permonth {
  margin-top: -15px;
  font-style: italic;
}

.package-explore {
  font-weight: bold;
  font-size: rem-calc(12);
  color: $primary;
}

.inline {
  display: inline !important;
}

.especial-margin-img {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 25%;
    left: -3%;
    width: 78%;
    height: 81%;
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
    background: $secondary;
    transform: skew(-32deg, 0deg);
    z-index: -1;
  }
}

.locations {
  text-align: left;
  // .fa-map-marker-alt{
  // 	color: $primary;
  // 	margin-top: 15px;
  //     margin-left: 22px;
  // }

  &_border {
    border: 1px solid $white;
    padding: 20px;
  }

  &_name {
    text-align: left;
    color: $text;
    font-size: rem-calc(15);
    font-weight: 600;
  }

  &_distance {
    text-align: left;
    color: $text;
    font-size: rem-calc(12);
    font-weight: 400;
    font-style: italic;
  }

  &_explore_more {
    margin-top: 10px;
  }
}
.enroll {
  p {
    font-size: rem-calc(16) !important;
    font-weight: 400;
    padding: 0 7px;
  }
}

.center-btn {
  margin: 0 auto;
  display: block;
}

.icon-holder {
  position: relative;

  .fa-id-card {
    width: 200px;
    height: 55px;
    color: white;
    position: absolute;
    left: 75px;
    top: 15px;
  }

  .fa-user-check {
    width: 200px;
    height: 55px;
    color: white;
    position: absolute;
    left: 70px;
    top: 3px;
  }

  .fa-dumbbell {
    width: 200px;
    height: 55px;
    color: white;
    position: absolute;
    left: 70px;
    top: 15px;
  }
}
.quote {
  // background: url("https://cfc879fb0219e1ba.azureedge.net/wp-content/uploads/2020/04/quote-hero.png") no-repeat;
  // background-size: cover;
  // height: 600px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 290px;
  }

  img {
    width: 100%;
  }

  .quote-text {
    background: #ffffffe6;
    padding: 50px 30px 10px 100px;
    position: absolute;
    top: -50px;
    max-width: 1200px;
    margin-left: 100px;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .fa-quote-left {
    font-size: 80px;
    color: $primary;
    padding-right: 10px;
  }

  .green {
    &::before {
      content: "";
      position: absolute;
      top: -70px;
      right: 102%;
      width: 60%;
      height: 135%;
      background: $secondary;
      transform: skew(-28deg, 0deg);
      z-index: 1;

      @media only screen and (max-width: 992px) {
        right: 125%;
      }

      @media only screen and (max-width: 768px) {
        top: -130px;
        right: 97%;
        width: 50%;
        height: 80%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -35px;
      right: 99%;
      width: 50%;
      height: 142%;
      background: $secondary;
      transform: skew(-28deg, 0deg);
      z-index: 0;
      opacity: 0.8;

      @media only screen and (max-width: 992px) {
        right: 123%;
      }

      @media only screen and (max-width: 768px) {
        top: -64px;
        right: 97%;
        width: 20%;
        height: 63%;
      }
    }
  }

  p {
    font-size: rem-calc(18) !important;
  }
}

.last-section {
  background-color: $primary;
  color: $white;
  padding-bottom: 20px;
  padding-top: 50px;

  h2 {
    font-size: rem-calc(40);
    color: $white;
  }
  p {
    font-size: rem-calc(25) !important;
    color: $white;
  }
}

footer {
  background-color: $primary;
  color: $white;
  width: 100%;
  flex-shrink: 0;
  min-height: 40%;
  // position: absolute;
  // bottom:0;

  p {
    font-size: rem-calc(14) !important;
    color: $white;
  }

  a {
    color: $white;
    line-height: 3;
    font-size: rem-calc(16);

    &:hover {
      color: $white;
    }
  }
}

/* Location Search */

.location-search {
  min-height: calc(100vh - 75px);
}

.map {
  height: calc(100% - 70px);
}

.px-4.py-3 .btn-group-toggle .btn.btn-primary.active {
  border-color: white !important;
}

.border-primary.border-bottom {
  border-bottom-width: 4px !important;
}

button.rounded-circle {
  width: 60px;
  height: 60px;
}

@media (max-width: 992px) {
  .border-right {
    border: 0 !important;
  }
  body > nav.shadow {
    box-shadow: none !important;
  }
}

@media (min-width: 1366px) {
  .location-search__sidebar {
    max-width: 440px;
  }
}

/* just to make it work -> for now */

.row {
  margin: 0 0;
}

.no-margin {
  margin: 0 0 !important;
}

.no-padding {
  padding: 0 0;
}

.radio-btn-fixed {
  display: inline;
  white-space: nowrap;
}

.w-85 {
  width: 85% !important;
}

.map-fields {
  &__top {
    z-index: 2;
    right: 70px;
  }

  &__bottom {
    z-index: 1;
    right: 70px;
  }
}

.breadcrumb-alignment {
  margin: 0 0 -15px;
}

.breadcrumb-item.active {
  color: $primary;
  font-weight: 600;
}

.breadcrumb-item + .breadcrumb-item {
  @media only screen and (max-width: 508px) {
    padding-left: 0.2rem !important;
  }
}
.breadcrumb-item {
  &::before {
    display: none;
  }
}

.account-nav-item {
  color: $text;
  font-weight: 400;
}

.account-nav-item.active {
  color: $primary;
  font-weight: 600;
}

.enroll-chevron {
  padding-left: 10px;
}

.fa-chevron-right {
  padding-top: 2px;
}

.map2 {
  margin: 0;
  padding: 0;
  min-width: 41%;
  min-height: auto;
}

label.card.active {
  border: 4px solid $primary !important;
  // background-color: #006dc6 !important;
  // color: $white;

  // p{
  // 	color: $white !important;
  // }
}

.border.border-secondary {
  border-color: $secondary !important;
}

.blue-diagonal {
  &::before {
    content: "";
    width: 245px;
    height: 100%;
    position: absolute;
    right: 80px;
    top: 0px;
    background: $primary;
    z-index: -1;
    -webkit-transform: skew(-28deg, 0deg);
    transform: skew(-28deg, 0deg);

    @media only screen and (max-width: 1290px) {
      width: 160px;
    }

    @media only screen and (max-width: 1130px) {
      display: none;
    }
  }

  &::after {
    content: "";
    width: 145px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background: $primary;
    z-index: -1;
    border-radius: 5px;

    @media only screen and (max-width: 1130px) {
      width: 210px;
    }

    @media only screen and (max-width: 840px) {
      width: 40%;
      border-radius: 0 5px 5px 0;
    }
  }
}

.gray-diagonal {
  &::before {
    content: "";
    width: 245px;
    height: 100%;
    position: absolute;
    right: 15px;
    top: 0px;
    background: $light-gray;
    z-index: -1;
    -webkit-transform: skew(-28deg, 0deg);
    transform: skew(-28deg, 0deg);
  }
  &::after {
    content: "";
    width: 145px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background: $light-gray;
    z-index: -1;
    border-radius: 5px;
  }
}

.btn-right {
  position: absolute;
  top: -15px;
  right: 0;
}

.total-small {
  font-size: 12px;
  padding-right: 7px;
}

.total-cost {
  font-weight: 600;
  font-size: 28px;
}

.btn-secondary:hover {
  color: $white;
  background-color: $primary;
}
.btn-outline-primary {
  @include button-bg($white);
}
.btn-outline-secondary {
  @include button-bg($white);
}

.btn-outline-primary:hover {
  opacity: 1 !important;
}

.btn-outline-secondary:hover {
  opacity: 1 !important;
}

.select-fam-mem {
  border: 1px solid $light-gray;
  padding: 5px;
  margin: 0 0 10px;
  border-radius: 0.25rem;
}
.select-fam-mem.active {
  color: $primary;
  border: 1px solid $primary;
}

.form-check-input {
  position: relative !important;
  margin: 0 20px 0 10px;
}

.sticky-top {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  z-index: 1001;
  @media (max-width: 1170px) {
    z-index: 0;
  }
}

div.active::after {
  content: "" !important;
}

.modal-title {
  position: relative;
}

.modal-close {
  position: absolute;
  right: 25px;
  top: 25px;
}

.breadcrumb-border {
  border-bottom: 1px solid $light-gray;
}

.fixed-h {
  height: 687px;
}

.blue-gradient {
  // background-image: linear-gradient(-90deg, #006DC6, #D3FFFF);
  background-color: #2070a0;
}

.btn-especial {
  border: 1px solid $white;
  background-color: transparent;
  color: $white;
  border-radius: 5px;
  padding: 7px 30px;
  margin: 20px 0 25px;
}

.line-br {
  margin: 35px auto;
  display: block;
  border-top: 2px solid $light-gray;
  max-width: 650px;
}

.circle {
  width: 25px;
  height: 25px;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 50%;
  position: relative;
  margin: 0 20px 0;
}

.circle-num {
  color: $primary;
  position: absolute;
  top: -2px;
  left: 30%;
  font-weight: 600;
}

.vertical-line {
  width: 1px;
  height: 20px;
  background: $primary;
  margin: 20px auto 0 auto;
  position: absolute;
  left: 33px;
  top: 10px;

  @media only screen and (max-width: 768px) {
    height: 50px;
  }
}

.vertical-line-2 {
  width: 1px;
  height: 20px;
  background: $primary;
  margin: 20px auto 0 auto;
  position: absolute;
  left: 33px;
  top: 10px;

  @media only screen and (max-width: 768px) {
    height: 80px;
    top: 27px;
  }
}

.vertical-line-3 {
  width: 1px;
  height: 30px;
  background: $primary;
  margin: 20px auto 0 auto;
  position: absolute;
  left: 33px;
  top: 10px;

  @media only screen and (max-width: 768px) {
    height: 80px;
    top: 27px;
  }
}
.select-fam-mem {
  // border: 1px solid $light-gray;
  padding: 5px;
  margin: 0 0 10px;
  border-radius: 0.25rem;
}

.table-center {
  margin: 0 auto;
}

.table-space {
  border-spacing: 0px 15px;
  border-collapse: separate;
  line-height: 35px;
}

.position-relative {
  position: relative;
}

.floating-txt {
  position: absolute;
  right: 0;
  top: 150px;
}

.fixed-bottom {
  position: sticky !important;
}

.lp-list {
  li {
    list-style: disc;
  }
}

.btn-primary-disable {
  color: #6c757d;
  background-color: $light-gray;
}

.btn-primary-disable:hover {
  color: #6c757d !important;
}

/*-- Location Number Dots --*/
.location-number::before {
  content: ".";
  font-size: 72px;
  line-height: 0;
  position: relative;
  bottom: 5px;
  color: $primary;
}

.just-location-number {
  font-size: 1.5rem !important;
  line-height: 0;
  color: $text;
  padding-bottom: 20px;
}

.studio-number::before {
  content: ".";
  font-size: 72px;
  line-height: 0;
  position: relative;
  bottom: 5px;
  color: var(--orange);
}

.studio-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 30vh;
}

.location-details-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
}

.location-list-scroll:hover,
.studio-list-scroll:hover,
.location-details-list-scroll:hover {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 8px;
  }
}

.square {
  &__top {
    background-color: $primary;
    width: 150px;
    padding: 30px;
    margin: 0 auto;
    position: relative;

    @media only screen and (max-width: 768px) {
      width: 117px;
      padding: 25px;
    }

    @media only screen and (max-width: 576px) {
      width: 97px;
      padding: 25px;
    }

    &::before {
      content: "";
      width: 205px;
      height: 100%;
      position: absolute;
      right: -27px;
      top: 0px;
      background: $primary;
      transform: skew(-28deg, 0deg);
      z-index: 1;

      @media only screen and (max-width: 768px) {
        width: 163px;
        right: -23px;
      }

      @media only screen and (max-width: 576px) {
        width: 140px;
        right: -19px;
      }
    }

    &::after {
      content: "";
      width: 203px;
      height: 100%;
      position: absolute;
      right: -37px;
      top: 10px;
      background: $secondary;
      transform: skew(-28deg, 0deg);

      @media only screen and (max-width: 768px) {
        width: 155px;
        right: -32px;
        top: 12px;
      }

      @media only screen and (max-width: 576px) {
        width: 135px;
        right: -26px;
        top: 10px;
      }
    }

    img {
      z-index: 20;
      position: relative;
      max-width: 100%;
    }
  }

  &__bottom {
    background-color: $secondary;
    width: 60%;
    padding: 30px;
    margin: 0 auto;
  }
}

.loading-spinner-locations {
  position: "absolute";
  top: 30%;
  right: 28%;
  margin: 0 auto;
  display: block;
  width: 200px;
}

.loading-spinner-map {
  margin: 30% auto;
  display: block;
  width: 150px;
}

.loading-spinner-payment {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loading-spinner-left {
  position: absolute;
  top: 50%;
  right: 40%;
  margin: 0px auto;
  display: block;
  width: 100px;
}

/*-- Overflow Control --*/
// .main-content{
//     height:calc(100vh - 215px);
//     overflow:hidden;
//     margin-bottom: 40px;
// }
.left-content {
  // overflow-x:hidden;
  // overflow-y:scroll;
  overflow-y: visible;
  padding-top: 9px;
}

/*--
    Bottom Actionbar Control;
    May wnat to readjust if footer needed on enrollment flow
--*/
.mobile-action-bar {
  height: 120px;
}
// .fa-check{
//     color:var(--orange);
// }

// .fa-check-circle{
//   color:var(--success);
// }

// .fa-trash{
//   color:$primary;
//   min-width:0px !important;
//   background-color:none;
//   border:0;
// }

.primary-bg {
  background-color: $primary;
}

.fixed-bottom {
  @media only screen and (max-width: 768px) {
    height: 130px;
  }
}

.mobile-card {
  @media only screen and (max-width: 768px) {
    border: 1px solid #ddd;
    color: $text;
    border-radius: 5px;
    width: 47%;
    margin: 0 4px;
    position: relative;
  }
}

label.mobile-card.active {
  background-color: #006dc6 !important;
  color: $white;

  p {
    color: $white !important;
  }
}

.punch-card {
  max-width: 350px;
}

.sign-up-footer {
  bottom: 0;
  flex-shrink: 0;
  width: 100%;
  padding: 25px 0;
  margin-top: 20px;
  position: sticky;
  @media screen and (max-width: 576px) {
    position: fixed;
  }
}

.edit-plans-footer {
  bottom: 0;
  flex-shrink: 0;
  width: 100%;
  padding: 25px 0;
  margin-top: 20px;
}

.forced-height {
  min-height: 450px;
}

.member-card {
  border-color: #ddd;
  color: $text;
  border-radius: 5px 5px 0 0;
  transition: box-shadow 0.15s ease-in-out;

  &__bottom {
    border: 1px solid #dee2e6;
    border-radius: 0 0 5px 5px;
  }
}

.app-phone-img {
  display: block;
  margin: 0 auto;
}

.location-pin-lp {
  width: 12px;
  position: absolute;
  top: -10px;
}

.discs {
  li {
    list-style: disc;
  }
}

.react-datepicker__input-container {
  input {
    color: #666;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding-left: 5px;
  }
}

.lp {
  p {
    font-size: rem-calc(16) !important;
  }
}

.studio-classes {
  p {
    font-size: rem-calc(16) !important;
  }
}

.btn-group-toggle {
  .btn {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary !important;

    &.active {
      color: $white;
    }
  }
}

.custom-radio-sup {
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    position: relative;
    background-color: $white;
    color: $primary;
    top: 0px;
    right: 0px;
    height: 21px;
    width: 21px;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    margin-right: -10px;
    outline: none;
    border: 3px solid $primary;

    &:checked {
      background-color: $primary;
    }
  }

  span {
    background-color: $primary;
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -6px;
      width: 112%;
      height: 24px;
      background: $primary;
      -webkit-transform: skew(-28deg, 0deg);
      transform: skew(-28deg, 0deg);
      z-index: -1;
    }
  }

  .fa-check {
    color: $gray;
    font-size: 10px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  &.active {
    span {
      background-color: $primary;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -6px;
        width: 112%;
        height: 24px;
        background: $primary;
        -webkit-transform: skew(-28deg, 0deg);
        transform: skew(-28deg, 0deg);
        z-index: -1;
      }
    }

    .fa-check {
      color: $secondary;
    }
  }
}

.custom-radio-inf {
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    position: relative;
    background-color: $white;
    color: $primary;
    top: 30px;
    right: 15px;
    height: 21px;
    width: 21px;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    margin-right: -10px;
    outline: none;
    border: 3px solid $primary;

    &:checked {
      background-color: $primary;
    }
  }
}

.plus-gyms {
  text-align: left;
  font-weight: bold;
  font-size: rem-calc(14);

  .fa-check {
    color: $gray;
    font-size: 10px;
    font-weight: bold;
    margin-right: 5px;
  }
}

.price-block {
  display: block;
  margin: 0 auto;
}

.price {
  font-size: rem-calc(54);
  font-weight: 800;
  font-style: italic;
  position: relative;
  @media only screen and (max-width: 1300px) {
    font-size: rem-calc(50);
  }
}

.per-month {
  font-size: rem-calc(12);
  padding-left: 5px;
  // position: absolute;
  //    right: 40px;
  //    top: 63px;
}

.card-break-line {
  border-top: 1px solid $gray;
  width: 100%;
  // margin-left: -16px;
  background-color: #f8f8f8;
}

.sp-des {
  font-size: rem-calc(12);

  .offer {
    font-size: rem-calc(14);
    font-weight: bold;
  }

  .fa-check {
    vertical-align: top;
  }
}

.flex-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-column-gap: 3%;
}

.location-amenities-hr {
  border: 2px solid $primary;
}

.amenities-list {
  ul {
    li {
      padding: 1rem 0;
      border-bottom: 1px solid #0000001a;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.amenities-chevron {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: rem-calc(22);
  color: $secondary;
}

.pagination {
  a {
    cursor: pointer;
  }
}

.location-count {
  min-width: 100px;
}

.primary-color {
  color: $primary;
}

.secondary-color {
  color: $secondary;
}

.pointer {
  cursor: pointer;
}

.error {
  font-size: rem-calc(180) !important;
  font-weight: 300 !important;
}

.page-not-found {
  font-size: rem-calc(24);
  font-weight: bold;
  color: $text;
}

.border-elite {
  border: 1px solid $secondary;
}

.standard-gyms-pin {
  .fa-map-marker-alt {
    color: $primary;
  }
}

.elite-gyms-pin {
  .fa-map-marker-alt {
    color: $secondary;
  }
}

.overlay {
  //height: 200vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  transition: 0.5s;
}

.faq {
  p {
    font-size: 14px;
  }

  .h3 {
    font-size: 25px;
    font-weight: bold;
  }

  button {
    color: $primary;
    background: $white;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0px 0px;
    padding: 20px 18px;
    width: 100%;
    text-align: left;
  }

  .panel {
    background: $light-gray;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0px 0px 4px 4px;
    padding: 20px 18px;
  }
}

.tier_description {
  font-size: 0.875rem;
  display: inline;
  margin-left: 10px;

  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    color: $secondary;
    display: inline;
    font-weight: 800;
  }

  b {
    padding-left: 5px;
  }
}

.calc-fontsize {
  font-size: 1rem;
  @media screen and (max-width: 650px) {
    font-size: rem-calc(14);
  }
  @media screen and (max-width: 510px) {
    font-size: rem-calc(10);
  }
}

.upcoming-paymenttext {
  @media screen and (max-width: 321px) {
    font-size: 0.9rem;
  }
}

.best-value-card::after {
  content: "the best value.";
  position: absolute;
  color: $primary;
  top: -25px;
  left: 25%;
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  font-size: rem-calc(14);
  letter-spacing: 1px;
  @media screen and (max-width: 1550px) {
    left: 13%;
    font-size: rem-calc(13);
  }
  @media screen and (max-width: 1350px) {
    left: 11%;
    font-size: rem-calc(11);
  }
  @media screen and (max-width: 1199px) {
    left: 16%;
  }
  @media screen and (max-width: 1162px) {
    left: 14%;
    font-size: rem-calc(13);
    letter-spacing: 0px;
  }
  @media screen and (max-width: 991px) {
    letter-spacing: 0px;
    font-size: rem-calc(14);
    left: 25%;
    bottom: -30px;
  }
  @media only screen and (max-width: 768px) {
    left: 27%;
    font-size: rem-calc(14);
  }
  @media only screen and (max-width: 634px) {
    bottom: -2px;
    left: 30%;
  }
  @media only screen and (max-width: 621px) {
    left: 30%;
    bottom: -2px;
  }
  @media only screen and (max-width: 575px) {
    left: 30% !important;
    bottom: -2px;
    font-size: rem-calc(16);
  }

  @media only screen and (max-width: 577px) {
    top: 288px !important;
  }
}

.flex-basis-auto {
  flex-basis: auto !important;
}

.fixed-mobile-navbar {
  position: fixed;
  left: 0;
  opacity: 1;
  width: 100vw;
  top: 75px;
  z-index: 1001;
}
// ----------------- responsiveness for manage dependents --------------------------- \\
.btn-minwidth {
  @media screen and (max-width: 593px) {
    min-width: 140px;
  }
}
// ---------------- end of responsiveness for manage dependents --------------------- \\

.view-account {
  &__button {
    position: relative;
  }

  &__chevron {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 17px;
    right: 8px;
  }
}

.tb-spaceing {
  td {
    line-height: 1.7;
  }
}

.w-btn-confirmation {
  width: 50%;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.confirmation {
  a {
    text-decoration: underline;
  }
}
// -----------------------  styles for the empty lists ------------------------------- \\
.height-500 {
  height: 500px;
}
.height-250 {
  height: 250px !important;
}

.height-150 {
  height: 150px !important;
}
// -------------------- end of styles for the empty lists ------------------------------- \\

// ============== FILL COLORS BY CLIENT ============== \\
.fill-primary {
  fill: $primary;
}

.fill-secondary {
  fill: $secondary;
}

.fill-white {
  fill: $white;
}
// ============== END FILL COLORS BY CLIENT ============== \\

.text-underline-primary {
  text-decoration: underline $primary;
}

.text-sm-center {
  @media screen and (max-width: 576px) {
    text-align: center;
  }
}

.w-sm-100 {
  @media screen and (max-width: 576px) {
    width: 100% !important;
  }
}
.w-md-100 {
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.w-lg-100 {
  @media screen and (max-width: 992px) {
    width: 100% !important;
  }
}
.w-xl-100 {
  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
}

.logout-logo {
  width: 234px;
  height: 41px;
}

.text-sm {
  font-size: rem-calc(14);
}

.text-lg {
  font-size: rem-calc(20);
}

//-------------- PRIMARY CHECKBOX STYLING ---------------\\
.checkbox-wrapper {
  .checkmark {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    position: relative;
    border: 2px solid $primary;
    transition: background-color 0.1s;
    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 12px;
      border-right: 3px solid $white;
      border-bottom: 3px solid $white;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(40deg) scale(3);
      opacity: 0;
      transition: all 0.1s;
    }
  }
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: $primary;
  }
  input[type="checkbox"]:checked ~ .checkmark::after {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
  }
}

//-------------- END OF PRIMARY CHECKBOX STYLING ----------\\

.elite-tag-loc {
  position: absolute;
  top: 0;
  right: 8px;
}

.home-gym-tag-loc {
  position: absolute;
  top: 18px;
  right: 0;
}

.bg-primary {
  background-color: $primary !important;
}

#submitButton {
  .btn-submit {
    display: none !important;
  }
}

.badge-skewed-primary {
  text-transform: uppercase;
  background-color: $primary;
  color: $white;
  transform: skew(-20deg);
  p {
    transform: skew(20deg);
  }
}

.mw-350 {
  max-width: 350px;
}

.nowrap-responsive {
  white-space: normal;
  @media screen and (min-width: 576px) {
    white-space: nowrap;
  }
}

.next-line > a {
  float: left;
  word-break: break-word;
  height: 100px;
  padding: 15px;
}

.nav-btn-enroll {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 17px !important;
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    margin: 5px 0 5px 0;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 15px !important;
  }
}
