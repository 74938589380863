.root {
  background-color: #f3f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-top: 40px;
}

.headerText {
  font-weight: bold !important;
  font-size: 2.5rem !important;
}

.subHeaderText {
  font-style: italic;
} 